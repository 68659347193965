/* index.js - redux store file */

/* Import Libraries */
import { createStore, applyMiddleware } from 'redux';

/* Import reducers */
import rootReducer from '../reducers/index';

/* Import initial state data */



const stateData = {

};

/* Middleware */

let console = window.console;

const logger = store => next => action => {
    let result;
    console.groupCollapsed('dispatching', action.type);
    console.log('prev state', store.getState());
    console.log('action', action);
    result = next(action);
    console.log('next state', store.getState());
    console.groupEnd();
    return result;
};

const saver = store => next => action => {
    let result = next(action);
    localStorage['redux-store'] = JSON.stringify(store.getState());
    return result;
};



const storeFactory = (initialState = stateData) => {
    return createStore(
        rootReducer(),
        (localStorage['redux-store']) ?
            JSON.parse(localStorage['redux-store']) :
            initialState,
        applyMiddleware(logger, saver)
    );
};

export default storeFactory;
