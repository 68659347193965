/* IThunk.js - a react home component */

/* Import Libraries */
import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Switch, NavLink } from 'react-router-dom';


/* Import Components */
import NavMenu from '../menus/NavMenu';
import HtmlImage from '../common/HtmlImage';

/* Import Redux Actions */


/* Import Stylesheets */
import '../../stylesheets/components/pages/ithunk.css';

/* Import images */
import FatFandangoFront from '../../images/Fat-Fandango-Front-Web.jpg';
import FatFandangoBack from '../../images/Fat-Fandango-Back-Web.jpg';
import FatFandangoInsideLeft from '../../images/Fat-Fandango-Inside-Left-Web.jpg';
import FatFandangoInsideRight from '../../images/Fat-Fandango-Inside-Right-Web.jpg';
import JoeyMario from '../../images/joey-mario.jpg';
import JoeyFloyd from '../../images/joey-floyd.jpg';
import JoeyWhiskey1 from '../../images/joey-whiskey-001.jpg';
import JoeyWhiskey2 from '../../images/joey-whiskey-002.jpg';
import JoeyWhiskey3 from '../../images/joey-whiskey-003.jpg';
import JoeyEricManu from '../../images/joey-eric-manu.jpg';
import JoeyKeanu from '../../images/joey-keanu.jpg';
import JoeyBoDiddley from '../../images/joey-bo-diddley.jpg';
import JoeyLauren from '../../images/joey-lauren.jpg';
import JoeyDvd from '../../images/joey-dvd.jpg';
import JoeyAllStars1 from '../../images/joey-haight-ashbury-all-stars.jpg';
import JoeyAllStars2 from '../../images/joey-san-fran-all-stars.jpg';
import JoeyPaulJimmy from '../../images/joey-paul-jimmy.jpg';
import JoeySlash from '../../images/joey-slash.jpg';
import JoeyAngie from '../../images/joey-angie.jpg';
import LaurenCd from '../../images/lauren-cd.jpg';
import FatFandango from '../../images/fat-fandango.jpg';
import FatFandangoPromo from '../../images/fat-fandago-promo-photo-grunt.jpg';
import FatFandangoPromo2 from '../../images/fat-fandango-photo-jim-marshall.jpeg';
import GruntFanzine from '../../images/gruntmag-joey-fixed.jpg';

/* Redux functions */
const mapStateToProps = state => {
    return {

    };
};

const mapDispatchToProps = dispatch => {
    return {

    };
};

/* Helper Functions */

/* Inline components */

const Main = ({ loading, error }) => {

  const fontSize = {
    'font-size': '18pt'
  };

  const smallFontSize = {
    'font-size': '9pt'
  };

  return(
    <div className="ithunk">

      <NavMenu />

      {
        (loading) ?
          <span>Loading I Thunk Page</span> :

          <div className="content">

            <p>
              This page is on going and was originally created and kept up by Joey Covington over the years.......
            </p>

            <h1>
              Joey's been Down <span className="small-text">&</span> Loaded
            </h1>

            <p>
              “Sittin’ round thinkin’, thinkin’ and a thinkin’. And it ain’t doin’ me no good.
              I want to know, that everything that’s sung will remain. Well i thunk thunk thunk thunk a lot about it,
              realized my thunk is nothin’ but a lot of funk.  So bunk down on in my place.”
            </p>

            <p>
              “Only A Dream (hummm)”
            </p>

            <p>
              “Couldn’t think of anything better,”
            </p>

            <p>
              (excerpt from the song “Thunk” written by Joey Covington, released on Jefferson Airplane Bark) <br />
              (Copyright Jehrico Rose, Inc.)
            </p>

            <p style={fontSize}>
              The music remains and it’s time to get it out!
            </p>

            <h2>2019</h2>

            <h3>October</h3>

            <p>
              Purple Pyramid Records has re-released Joe E. Covington’s Fat Fandango
              to all online music platforms!! I.E. Apple Music, Spotify,
              I Heart Radio, Google Play, Etc. CD's coming in 2020!. This is Joey’s 1973 solo album,
              originally recorded for Jerferson Airplane's Grunt Records. Joey's musical legacy is now
              with the Record Label that has the solo work of his peers - Jimmy Page, Rick Wakeman, Santana,
              Chris Squire...
            </p>

            <p>
              Fat Fandango Album Artwork
            </p>

            <p>
              <HtmlImage id={"fat-fandango-front"}
                         src={FatFandangoFront}
                         alt={""}
                         style={{width: '400px', padding: '2px 5px'}} />
              <HtmlImage id={"fat-fandango-back"}
                        src={FatFandangoBack}
                        alt={""}
                        style={{width: '400px', padding: '2px 5px'}} />
              <HtmlImage id={"fat-fandango-inside-left"}
                       src={FatFandangoInsideLeft}
                       alt={""}
                       style={{width: '400px', padding: '2px 5px'}} />
              <HtmlImage id={"fat-fandango-inside-right"}
                      src={FatFandangoInsideRight}
                      alt={""}
                      style={{width: '400px', padding: '2px 5px'}} />
            </p>

            <p>
              Fat Fandango Youtube playlist
            </p>

            <p>
              <iframe width="560" height="315" src="https://www.youtube.com/embed/videoseries?list=PLUEA5tQFIucsQW3Fy4G6n7-dqFOP22YuW" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </p>

            <p>
              Fat Fandango Promo
            </p>

            <p>
              <HtmlImage id={"fat-fandango-promo"}
                         src={FatFandangoPromo}
                         alt={""}
                         style={{width: '400px', padding: '2px 5px'}} />
              <HtmlImage id={"fat-fandango-promo2"}
                        src={FatFandangoPromo2}
                        alt={""}
                        style={{width: '400px', padding: '2px 5px'}} />
            </p>

            <p>
              Grunt Records Fanzine
            </p>

            <p>
              <HtmlImage id={"grunt-fanzine"}
                       src={GruntFanzine}
                       alt={""}
                       style={{width: '400px', padding: '2px 5px'}} />
            </p>

            <h2>2013</h2>

            <p>
              Drummer Alvin Taylor (Little Richard, George Harrison, Elton John) has put together a tribute concert Sat.
              Aug. 31, 2013 8pm PST. This concert features an All Star Line up of players from groups such as Buddy Miles Express,
              Bad Company, Greg Allman Band, Big Brother and the Holding Company and many more to honor the life of his friend
              Joey Covington. The concert is being Live Streamed Worldwide so those not able to attend the Live show can buy tickets
              to see the show via the internet.
            </p>

            <h2>2009</h2>

            <p>
              January<br />
              Well, what happened to 2008  ?<br />
              “ I wrote 29 new songs !”
            </p>

            <p>
              HAPPY 2009 EVERYONE !<br />
              (And special good wishes for 2009 to the citizens of Vicenza, Italy)
            </p>

            <h2>2008</h2>

            <p>
              <HtmlImage id={"joey-mario"}
                         src={JoeyMario}
                         alt={""}
                         style={{width: '483px'}} /><br />
             Joey & Mario Maglieri of the Whisky A Go Go<br />
             (right before Mario’s interview with Larry King)
            </p>

            <h2>2007</h2>

            <h3>December</h3>

            <p>
              <HtmlImage id={"joey-floyd"}
                         src={JoeyFloyd}
                         alt={""}
                         style={{width: '165px'}} /><br />
              To my friend, Floyd "Red Crow" Westerman : <br /><br />
              Let the winds blow, through your hair. <br />
              Let the clouds cry, and wash away the pain. <br />
              Let the sun shine, while it  keeps your spirit warm, <br />
              As you finally fly with the eagle. <br />
              Hey Floyd, I listened  to your CD <br />
              “A Tribute to Johnny Cash”, <br />
              Great Job.<br />
              Your Friend,<br />
              Joey Covington
            </p>

            <h3>September</h3>

            <p>
              Joey’s been in the studio recording an album of his new songs with guitarist Chris Seefried (GodChild, Roseann Cash, Counting Crows)
            </p>

            <h3>June</h3>

            <p>June 14th</p>
            <p>
              Special appearance by Joey Covington at the 1st Annual Sunset Strip Music Festival at the Whisky A Go Go.
              The show was a Tribute to James Brown, with other celebrity guests.
              The Show proceeds will be donated in the memory of James Brown to the Sunset Strip Rock N’ Roll Hall of Fame.
            </p>

            <h3>March</h3>

            <p>
              Joey Covington and Sky Saxon (The Seeds) covered Ronnie VanZant’s “Swamp Music” for Cleopatra Records and it has been released
              on the CD - A TRIBUTE TO LYNYRD SKYNYRD <br />
              (2019 - now on all online platforms)
            </p>

            <h3>February</h3>

            <p>
              If you’re headed to L.A. the best restaurant, best food, and best hang is Mario Maglieri’s Rainbow Bar & Grill
              on the Sunset Strip.  There’s a reason it’s a “landmark”.  And Mario’s Whisky A Go Go is still “the club” that’s
              Rockin’ L.A.    Joey wants to say Rock On to Mario for his induction in to the Rock N’ Roll Hall of Fame this year
              and for his contribution to Rock N’ Roll!   The book “Straight Whisky” documents the history of the Whisky A Go Go;
              a must read for Rock fans.
            </p>

            <h3>January</h3>

            <p>
              Joey wants to let everyone know that his friend Jerry Schilling recently had his book published called
              “Me and a Guy Named Elvis” on Gotham Books.  Jerry was a close friend of and a part of Elvis’s “Memphis Mafia”.
              His book is written with the deep sensitivity that only a friend could have.  Jerry writes of things that the
              public never knew before and dispels a lot of the misinformation that has been said or written over the years
              about Elvis.     It’s a great book !
            </p>

            <h2>2006</h2>

            <p>
              Teaming up with singer/songwriter Chris Seefried, the song “Fine” they wrote is featured in the upcoming movie - In
              The Blink of An Eye, starring Jaime Gomez (Nash Bridges) and Brian Krause (Charmed).
            </p>

            <h3>November</h3>

            <p>
              Craig Fenton's book Take Me To A Circus Tent: The Jefferson Airplane Flight Manual was published.
              Check out Joey's in depth interview.
            </p>

            <h3>October</h3>

            <p>
              HOLDING TOGETHER MAGAZINE - in depth Joey Covington interview
            </p>

            <h3>September</h3>

            <p>
              12pm LA NiteLife 30 minute LIVE interview with James Bartolet on Local LA Cable channels 3 and 77 - Time Warner Cable
            </p>

            <p>
              September 25<br />
              Joey Covington & Friends featuring singer LAuren Rocked the Whisky A Go Go on the Sunset Strip
            </p>

            <h3>August</h3>

            <p>
              Joey Covington covers Ronnie VanZant’s “Swamp Music” for Cleopatra Records. Watch for it’s upcoming release.
            </p>

            <h3>July</h3>

            <p>
              Kickin’ Ass at The Whiskey A Go Go  on the Sunset Strip
            </p>

            <p>
              <HtmlImage id={"joey-whiskey-001"}
                         src={JoeyWhiskey1}
                         alt={""}
                         style={{width: '288px', padding: '5px'}} />
             <HtmlImage id={"joey-whiskey-002"}
                        src={JoeyWhiskey2}
                        alt={""}
                        style={{width: '363px', padding: '5px'}} />
            <HtmlImage id={"joey-whiskey-003"}
                       src={JoeyWhiskey3}
                       alt={""}
                       style={{width: '199px', padding: '5px'}} /> <br />
                       <span style={smallFontSize}>
                         photos by Scott Mitchell <br />
                         wardrobe courtesy of Scott & Co.  West Hollywood, California
                       </span>
            </p>

            <h3>June</h3>

            <p>
              June 20
            </p>

            <p>
              Finland and Friends Benefit Jam for MUSICARES<br />
              Sponsored by the Consul General of Finland Manu Virtamo<br />
              Musicares is an organization devoted to helping take care of the needs of musicians
            </p>

            <p>
              <HtmlImage id={"joey-eric-manu,"}
                         src={JoeyEricManu}
                         alt={""}
                         style={{width: '216px'}} /> <br />
               <span style={smallFontSize}>
                 Eric Knudson, Manu Virtamo, Joey Covington
               </span>
            </p>

            <h3>May</h3>

              <p>
                <HtmlImage id={"joey-keanu"}
                           src={JoeyKeanu}
                           alt={""}
                           style={{width: '393px'}} /> <br />
                 <span style={smallFontSize}>
                   Musicians hanging out in Hollywood
                 </span>
              </p>

              <br /><br />

            <h3>January</h3>

            <p>
              Islamorada, Florida Benefit For Katrina Victims (Ocean Springs, Mississippi)<br />
              Joey drummed with the legendary Bo Diddley..<br />
              A two hour set<br />
              Opening for them was Clarence Clemmons
            </p>

            <p>
              <HtmlImage id={"joey-bo-diddley"}
                         src={JoeyBoDiddley}
                         alt={""}
                         style={{width: '386px'}} /> <br />
               <span style={smallFontSize}>
                 photo courtesy of Belinda Serata
               </span>
            </p>

            <br /><br />

            <p>
              JOEY COVINGTON & FRIENDS GO LIVE featuring singer, LAuren
            </p>

            <p>
              Live Shows include a performance by Joey of the original version of his hit song for
              Jefferson Airplane “Pretty As You Feel”
            </p>

            <p>
              Special Thanks to Pilsner Urquell for taking care of the band and crew vibe!!
            </p>

            <p>
              <HtmlImage id={"joey-lauren"}
                         src={JoeyLauren}
                         alt={""}
                         style={{width: '386px'}} /> <br />
               <span style={smallFontSize}>
                 Joey Covington & LAuren<br />
                 Photo by Bill Day
               </span>
            </p>

            <br />

            <h2>2005</h2>

            <h3>THE PAST CATCHES UP</h3>

            <p>
              Interview and digging in the past for Jeff Tamarkin’s book “Got A Revolution! The Turbulent
              Flight of Jefferson Airplane”  (Atria Books)
            </p>

            <p>
              Interview for the Fly Jefferson Airplane DVD
            </p>

            <h2>September</h2>

            <p>
            The Sept. 2005 DVD screening in L.A. had Joey Covington, Jorma Kaukonen, Jack Cassady, Grace Slick, manager
            Bill Thompson, and Maurice Ierci “master of the machines” all in one place.  Who would have ever thought….<br />
            “It was great seeing everyone and the DVD is great and very well made.”
            </p>

            <p>
              <HtmlImage id={"joey-dvd"}
                         src={JoeyDvd}
                         alt={""}
                         style={{width: '503px'}} /> <br />
               <span style={smallFontSize}>
                 left to right
                 BMG’s guy, Bill Thompson, Bob Sarles, Jorma Kaukonen, Joey Covington, Maurice Ierci, Grace Slick, and Jack Casady <br />
                 (Photo courtesy of Andrew Orth)
               </span>
            </p>

            <br />

            <h2>What’s Gone Down The Pike</h2>

            <p>
              Late 1970’s,1980’s and 1990’s – San Fransisco AllStars & other Tours, <br />
              with Papa John Creach, David Margen, John Dawson, John Cipollina, Mario Cipollina, Nick Gravenitis,
              Greg Douglas, Steve Love,  Willy Chambers, Buddy Cage, Jerry Miller, Peter Lewis, Bob Mosely, Peter Albin,
              Merle Saunders, Pete Sears
            </p>

            <br />

            <p>
              The San Francisco Haight Asbury AllStars  1977
            </p>

            <p>
              <HtmlImage id={"joey-haight-ashbury-all-stars"}
                         src={JoeyAllStars1}
                         alt={""}
                         style={{width: '412px'}} /> <br />
               <span style={smallFontSize}>
                 Spencer Dryden, Pete Sears, Joey Covington, John Cipollina, Nick Gravenitis,  Billy Roberts<br />
                 (Bottom left) (top 3rd from left) (center) (top 2nd from right) (Bottom right) (top right)<br />
                 special thanks to Mike Somavilla for the photo
               </span>
            </p>

            <p>
              San Francisco AllStars 1st lineup  1978
            </p>

            <p>
             <HtmlImage id={"joey-san-fran-all-stars"}
                        src={JoeyAllStars2}
                        alt={""}
                        style={{width: '433px'}} /> <br />
              <span style={smallFontSize}>
                Joey Covington, John Cipollina, Mario Cipollina, Buddy Cage, Steve Love<br />
                special thanks to Mike Somavilla for the photo
              </span>
            </p>

            <br />

            <h3>ALLSTAR JAMS & TOURS 1990’s and 2000’s</h3>

            <p>
              Spencer Davis, Mike Finnegan, Skunk Baxter, Billy Roberts, Spencer Dryden, Slash,
              Paul Shortino, Jimmy Crespo, James Gurley, Brian May, Albert Collins, Joe Shermie, Mike Bloomfield,
              Fuzzy Knight, Joe Chambers, Rick Danko, Bruce Willis, Kiefer Sutherland, Max Gail, Jr., Gary Busey,
              Merl Saunders...
            </p>

            <p>
              <HtmlImage id={"joey-paul-jimmy"}
                         src={JoeyPaulJimmy}
                         alt={""}
                         style={{width: '477px'}} /> <br />
              <span style={smallFontSize}>
                Jimmy Crespo, Paul Shortino, Joey Covington
              </span>
            </p>

            <p>
              <HtmlImage id={"joey-slash"}
                        src={JoeySlash}
                        alt={""}
                        style={{width: '243px'}} /> <br />
              <span style={smallFontSize}>
                Slash &  Joey
              </span>
            </p>

            <p>
              Lots of recording sessions
            </p>

            <p>
              FOR DRUMMERS ONLY:
            </p>

            <p>
              <HtmlImage id={"joey-angie"}
                        src={JoeyAngie}
                        alt={""}
                        style={{width: '387px'}} /> <br />
              <span style={smallFontSize}>
                Drum lessons for Angelina Jolie
              </span>
            </p>

            <br />

            <h2>FOR THE CAUSE</h2>

            <p>
              Benefit in St. Louis for Music Education<br />
              Benefit for the Ronald MacDonald Foundation<br />
              Benefit for Familes To Amend the California Three Strikes Law<br />
              (with Joe Chambers)<br />
              Benefit for NYFD victims of 911<br />
              (with Chris Mancini and LAuren)<br />
              Benefit music video for Tsunami Relief<br />
              (with Brian McKnight)<br />
              Benefit for Katrina Victims Ocean Springs, Miss.<br />
              Consul General of Finland Benefit for Musicares
            </p>


            <h2>IN THE WORKS</h2>

            <p>
              CD San Fran Jam (Live)<br />
              CD The Nitrous Sessions (Live)<br />
              CD Joey Covington's Songs From Then And Now
            </p>

          </div>

      }
      {
        (error) ? <p>Error loading I Thunk Page </p> : ''
      }

    </div>

  );

};


class IThunk extends Component {

    /* Constructor */
    constructor(props) {
        // Initialize parent
        super(props);

        // set default state for component
        this.state = {
            loading: false,
            error: null
        };
        console.log('I Thunk constructor');
    }

    /* component will mount react life cycle function */
    componentWillMount(){

    }

    /* component will update react life cycle function  */
    componentWillUpdate() {

    }

    /* component did update react life cycle function  */
    componentDidMount() {

    }

    /* component render function  */
    render() {
        // load state variables
        const { loading, error } = this.state;

        return(

            <Switch>

                <Route exact path="/ithunk"
                    render={() => (
                        <Main loading={loading} error={error} />
                    )}
                />

            </Switch>

        );

    }

};

const IThunkCntr = connect(mapStateToProps, mapDispatchToProps)(IThunk);

export default IThunkCntr;
