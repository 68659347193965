/* Girlfriend.js - a react girlfriend component */

/* Import Libraries */
import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Switch, NavLink } from 'react-router-dom';


/* Import Components */
import NavMenu from '../menus/NavMenu';


/* Import Redux Actions */


/* Import Stylesheets */
import '../../stylesheets/components/pages/girlfriend.css';


/* Import images */


/* Redux functions */
const mapStateToProps = state => {
    return {

    };
};

const mapDispatchToProps = dispatch => {
    return {

    };
};

/* Helper Functions */


/* Inline components */

const Main = ({ loading, error }) => {

  const boldStyle = {
    'font-weight': 'bold'
  };

  const fontSize = {
    'font-size': '12pt'
  };

  const biggerFontSize = {
    'font-size': '16pt'
  };

  const biggerFontSize2 = {
    'font-size': '20pt'
  };

  const biggerFontSize3 = {
    'font-size': '24pt'
  };

  const centerBoldStyle = {
    'font-weight': 'bold',
    'text-align': 'center'
  };

  const setMargin = {
    'margin': '0px'
  };

  return(
    <div className="girlfriend">

      <NavMenu />

      {
        (loading) ?
          <span>Loading Girlfriend Page</span> :

          <div className="content">

            <br /><br />

            <p style={{...biggerFontSize2, ...boldStyle}}>What's a Drummer without a girlfriend?</p>

            <h1 style={{...boldStyle}} >
              HOMELESS !
            </h1>

            <p style={boldStyle}>~~~~~~</p>

            <br />

            <p className="joke_question" style={{...biggerFontSize2, ...boldStyle}}>
              How do you know there's a drummer knocking at your door?
            </p>

            <p className="joke_answer" style={{...biggerFontSize3, ...boldStyle}}>
              The knock speeds up!
            </p>

            <p style={boldStyle}>~~~~~~</p>

            <br />

            <p className="joke_question" style={{...biggerFontSize2, ...boldStyle}}>
              Two women were walking down the road when they heard a voice. They looked down and saw an ugly frog.<br /><br />
              "Kiss me, and I'll turn into a world famous drummer", the frog said.<br /><br />
              One of the women immediately reached down, grabbed the frog and put him in her pocket.<br /><br />
              "Are you crazy?" her friend asked. <br /><br />
              "Of course not", she replied.
            </p>

            <p className="joke_answer" style={{...biggerFontSize3, ...boldStyle}}>
              "Don't you  know that a talking frog is worth a lot more than a famous drummer!!!!!!!!!"
            </p>

            <p className="joke_credit">
              Thanks Ernie Durawa, Drummer!
            </p>

            <p style={boldStyle}>~~~~~~</p>

            <br />

            <p className="joke_question" style={{...biggerFontSize2, ...boldStyle}}>
              Who's the guy who always sits behind the band?
            </p>

            <p className="joke_answer" style={{...biggerFontSize3, ...boldStyle}}>
              The drummer!
            </p>

            <p className="joke_credit" >
              Thanks Count Smokula aka Smokey Miles
            </p>

            <p style={boldStyle}>~~~~~~</p>

            <br />

            <p className="joke_question" style={{...biggerFontSize2, ...boldStyle}}>
              Stevie Ray Vaughn wakes up in the studio one morning, ............................. the door opens ... and
              as Stevie Ray looks up ...in walks Jimi Hendrix.<br /><br />
              Hendrix plugs in, and starts tuning up ......... the door opens again, and in walks Elvis.<br /><br />
              Stevie Ray goes, "WOW"!<br /><br />
              While Elvis grabs a mike, the door opens again and in walks Jerry Garcia....Jerry plugs in.............<br /><br />
              Stevie Ray by now is wide awake saying "Wow, I must be in Rock N' Roll Heaven!!!!!<br /><br />
              The door opens again and in walks Karen Carpenter. Karen sits down at the drums and says,
            </p>

            <p className="joke_answer" style={{...biggerFontSize3, ...boldStyle}}>
              "Hit It Boys"!
            </p>

            <p className="joke_credit">
              Thanks Chris M.
            </p>

            <p style={boldStyle}>~~~~~~</p>

            <br />

            <p className="joke_question" style={{...biggerFontSize2, ...boldStyle}}>
              What do you call someone who hangs out with musicians?
            </p>

            <p className="joke_answer" style={{...biggerFontSize3, ...boldStyle}}>
              A DRUMMER!
            </p>

            <p className="joke_credit">
               Thanks Steve Keyser
            </p>

            <p style={boldStyle}>~~~~~~</p>

            <br />

            <p className="joke_question" style={{...biggerFontSize2, ...boldStyle}}>
              How many drummers does it take to change a lightbulb?
            </p>

            <p className="joke_answer" style={{...biggerFontSize3, ...boldStyle}}>
               One to change it and 99 to stand around and say "I can do that!"
            </p>

            <p style={boldStyle}>~~~~~~</p>

            <br />

            <p className="joke_question" style={{...biggerFontSize2, ...boldStyle}}>
              Why'd the drummer show up on time for the gig?
            </p>

            <p className="joke_answer" style={{...biggerFontSize3, ...boldStyle}}>
              He thought it was rehearsal!
            </p>

            <p className="joke_credit">
              Thanks Phillip J. Varriale
            </p>

            <p style={boldStyle}>~~~~~~</p>

            <br />

            <p className="joke_question" style={{...biggerFontSize2, ...boldStyle}}v>
              What's the difference between a drummer and a 15" pizza?
            </p>

            <p className="joke_answer" style={{...biggerFontSize3, ...boldStyle}}>
              The pizza can feed a family of four.
            </p>

            <p style={boldStyle}>~~~~~~</p>

            <br />

            <p className="joke_question" style={{...biggerFontSize2, ...boldStyle}}>
              What's the difference between a drummer and a savings bond?
            </p>

            <p className="joke_answer" style={{...biggerFontSize3, ...boldStyle}}>
              One of them will eventually mature!
            </p>

            <p style={boldStyle}>~~~~~~</p>

            <br />

            <p className="joke_question" style={{...biggerFontSize2, ...boldStyle}}>
              How can a drummer tell when the riser's level?
            </p>

            <p className="joke_answer" style={{...biggerFontSize3, ...boldStyle}}>
              The drool runs down from both sides of his mouth!
            </p>

            <p className="joke_credit">
              Steve Rowland - not a drummer - but I do publish the Airplane/Tuna/Starshp fanzine HOLDING TOGETHER<br />
              (You Rock Steve!!!)
            </p>

            <p style={boldStyle}>~~~~~~</p>

            <br />

            <h2>not quite drummer but  great anyways category :</h2>

            <p className="joke_question" style={{...biggerFontSize2, ...boldStyle}}>
              What does a stripper do with her asshole before going to work?
            </p>

            <p className="joke_answer" style={{...biggerFontSize3, ...boldStyle}}>
              She drops him off at band practice!
            </p>

            <p className="joke_credit">
              Thanks Steve York, M.D.
            </p>

            <p style={boldStyle}>~~~~~~</p>

            <br />

            <p className="joke_question" style={{...biggerFontSize2, ...boldStyle}}>
              How do you know when a singer is at your door?
            </p>

            <p className="joke_answer" style={{...biggerFontSize3, ...boldStyle}}>
              They can't find the key and they never know when to come in!
            </p>

            <p className="joke_credit">
              Thanks Scott Cooper, Editor,  Rock Village On Line
            </p>

            <p style={boldStyle}>~~~~~~</p>

            <br />

            <p style={{...biggerFontSize3, ...boldStyle, ...setMargin}}>Yes but, "The Drummer always gets the girl!"</p>
            <p style={{...biggerFontSize3, ...setMargin}}>Joey Covington</p>

            <br />

            <p className="">
              If you got one, send it in to BANDINUSA@aol.com
              and we'll post it.
            </p>

            <h1>Musically Yours,</h1>
            <h1>Joey Covington</h1>

          </div>

      }
      {
        (error) ? <p>Error loading Girlfriend Page </p> : ''
      }

    </div>

  );

};



class Girlfriend extends Component {

    /* Constructor */
    constructor(props) {
        // Initialize parent
        super(props);

        // set default state for component
        this.state = {
            loading: false,
            error: null
        };
        console.log('home constructor');
    }

    /* component will mount react life cycle function */
    componentWillMount(){

    }

    /* component will update react life cycle function  */
    componentWillUpdate() {

    }

    /* component did update react life cycle function  */
    componentDidMount() {

    }

    /* component render function  */
    render() {
        // load state variables
        const { loading, error } = this.state;

        return(

            <Switch>

                <Route exact path="/girlfriend"
                    render={() => (
                        <Main loading={loading} error={error} />
                    )}
                />

            </Switch>

        );

    }

};

const GirlfriendCntr = connect(mapStateToProps, mapDispatchToProps)(Girlfriend);

export default GirlfriendCntr;
