/* Drummer.js - a react so you want to be a drummer component */

/* Import Libraries */
import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Switch, NavLink } from 'react-router-dom';


/* Import Components */
import NavMenu from '../menus/NavMenu';
import HtmlImage from '../common/HtmlImage';


/* Import Redux Actions */

/* Import Stylesheets */
import '../../stylesheets/components/pages/drummer.css';


/* Import images */
import Johnstown from '../../images/johnstown.jpg';
import MusicSociety from '../../images/jtown-music-society-card.jpg';
import TheFenways from '../../images/the-fenways.jpg';


/* Redux functions */
const mapStateToProps = state => {
    return {

    };
};

const mapDispatchToProps = dispatch => {
    return {

    };
};

/* Helper Functions */


/* Inline components */

const Main = ({ loading, error }) => {

  const fontSize = {
    'font-size': '12pt'
  };

  const biggerFontSize = {
    'font-size': '16pt'
  };

  const biggerFontSize2 = {
    'font-size': '18pt'
  };

  const boldStyle = {
    'font-weight': 'bold'
  };

  return(
    <div className="drummer">

      <NavMenu />

      {
        (loading) ?
          <span>Loading So you want to be a drummer Page</span> :

          <div className="content">

            <h1>JOEY COVINGTON</h1>

            <p>
              <span style={{...biggerFontSize2, ...boldStyle}}>"So you wanna be a drummer son,.... huh?!  Okay, but you have to understand, there won't be any social security,
              and what about your medical?"</span> <br />
              (my dad)
            </p>

            <p>
              Copyright 1997,2006 & 2019 “All rights reserved”,  Jehrico Rose, Inc. <br />
              no portion of the following may be copied, duplicated or reproduced without the express permission
              of The Estate of Joey Covington
            </p>

            <h3 style={boldStyle}>VITALS</h3>

            <ul  style={boldStyle}>

              <li>
                home town - east conemaugh, pennsylvania (working class section)
              </li>

              <li>
                cancer with capricorn rising
              </li>

              <li>
                year - (hint:  Joey will always be the youngest member of  JEFFERSON AIRPLANE!)
              </li>

              <li>
                3rd of 6 children
              </li>

              <li>
                blond with blue eyes
              </li>

              <li>
                hobbies: collecting lionel ho trains, lead soldiers , indian head pennies
              </li>

              <li>
                high school nickname -  skinny joe
              </li>

              <li>
                best trait - persistence  (though some don't see it that way!)
              </li>

              <li>
                earliest aspiration - to be a rock n' roll star, age 15
              </li>

              <li>
                lost virginity - age 15
              </li>

              <li>
                favorite saying - "speak softly and carry a big stick!"
              </li>

              <li>
                percussion - self taught, age 10, by listening to joe morello, cozy cole, sandy nelson (drums), candido (congas),
                & preston epps (bongos)
              </li>

              <li>
                other instruments - all percussion, piano for songwriting
              </li>

              <li>
                plays all musical genres
              </li>

              <li>
                favorite musical styles- rock n' roll, r & b, blues, rockabilly, & jazz
              </li>

              <li>
                favorite artists - (in no particular order) henry mancini, jimmy reed, jimi hendrix experience, dave brubeck,
                bo diddley, little richard, james brown & the famous flames, elvis presley, ray charles, steely dan, isley bros.,
                miles davis , mitch mitchell, otis redding
              </li>

              <li>
                fondest early memories on the road to fame and fortune - age 20, "getting to new york city on a greyhound bus
                with a suitcase, a set of drums, and $100.00 in my pocket." and, "loading 6 drum cases on the E subway train to get
                to a rehearsal."
              </li>

            </ul>

            <br />

            <h3  style={boldStyle}>A YOUNG DRUMMER'S CAREER UNFOLDS</h3>

            <p>
              <HtmlImage id={"johnstown"}
                         src={Johnstown}
                         alt={""}
                         style={{width: '328px'}} /><br />
              <span style={fontSize}>Johnstown, PA</span>
            </p>

            <br />

            <p>
              age 10 to 13 - playing drums in polka bands at vfw lodges with mom and dad chaperoning, <br />
              <span style={boldStyle}>"cause I was underage"</span>
            </p>

            <p>
              age 14 - backing up strippers at the airway club in johnstown, pa.<br /><br />
              <span style={boldStyle}>"great fun for a 14 year old"</span> and
              <span style={boldStyle}>"no, mom and dad didn't know!"</span>.<br /><br />
              as crystal would say,<br /><br />
              <span style={boldStyle}>"boy, when i bump and grind my buns, you beat hell out of them tom toms!"</span><br /><br />
              <span style={boldStyle}>"thanks crystal, that was good advice!!! "</span><br />
              <span style={boldStyle}>"i never did get to tell you how nice those 22 year old buns were;  sittin there on my bass drum; shimmyin'
              and a shakin'".</span><br />
          </p>

          <br />

          <p>
            age 15 - 18 - the high school years; east conemaugh high school marching band ,<br />
            <span style={boldStyle}>"i came in as a tom tom drummer and left a drum seargent."</span> <br /><br />
            then there was the hot rod band that won a few battle of the bands, and opened once
            for simon and garfunkel. <br /><br />
            <span style={boldStyle}>"i learned about becoming a lead drummer with them, and got fired a few times for it;
            they didn't appreciate the push!"</span><br /><br />
            started singing while playing drums.
        </p>

        <br />

        <p>
          age 18 - 19 - time of the draft notices. <br /><br />
          <span style={boldStyle}>" the navy told me if i enlisted i could be a tympani drummer in the navy band".</span><br /><br />
          right before being shipped out to guide planes on to the u.s.s. kittyhawk, the hot rod band was in a car accident and
          a one year detour from the road to fame began.  broken pelvis, 3 broken toes, broken right leg, not expected to walk
          again. <br /><br />
          <span style={boldStyle}>"i meditated to focus the healing, and after 3 months in a pelvic sling i
          went home to recharge and rebuild."</span>
        </p>

        <br />

        <p>
          age 20 - <span style={{...biggerFontSize2, ...boldStyle}}>THE SINGLE GREATEST MOTIVATING FACTOR IN A YOUNG DRUMMER'S LIFE ARRIVES..</span> <br /><br />
          <span style={{...biggerFontSize2, ...boldStyle}}>" son, if you're gonna be a rock star, you've got a month to take your drums and get out on the road, or, if you're
          gonna live at home, you'd better get a job and bring some money into the house."</span><br />
        </p>

          <br />

        <p>
          the greyhound bus let joey and his grey marine ludwig pearls (just like ringo's) off in new york city's grand central
          station, exactly one month later.
        </p>

        <br /><br />

        <p>
          <span style={boldStyle}>"i headed to the peppermint lounge, introduced myself to joey d. and the
          starlighters (peppermint twist), and asked joey d. if he knew where i could find a gig".</span> <br /><br /><br />
          the next day i was at joey d.'s agent sid green's office.  joey d. said<br />
          <span style={boldStyle}>"he's a nice guy, you'll like him."</span><br /><br /><br />
          <span style={{...biggerFontSize, ...boldStyle}}>"mr green, joey d. sent me, i'm a drummer looking for a gig."</span><br /><br /><br />
          <span style={boldStyle}>"HEY KID, WHAT'S WITH THE LONG HAIR?"<br />
          "i haven't got anything for you right now, here's my card,.. give me a call."</span><br /><br /><br />
          <span style={{...biggerFontSize, ...boldStyle}}>"i'll wait here in case something comes up."</span><br /><br /><br />
          <span style={boldStyle}>"kid, i can't help you, i got no place to put you.  how old are you?...  20?  you're still wet behind the ears."<br />
          "how much ya weigh kid?"</span><br /><br /><br />
          <span style={{...biggerFontSize, ...boldStyle}}>"112 pounds, mr. green."</span><br /><br /><br />
          <span style={boldStyle}>"you telling me the truth kid, joey d. really sent you?"</span><br /><br /><br />
          <span style={{...biggerFontSize, ...boldStyle}}>"that's the god's honest truth, mr. green."..............</span><br /><br /><br />
          <span style={{...biggerFontSize2, ...boldStyle}}>ABOUT FIVE HOURS LATER..............</span><br /><br /><br/>
          <span style={boldStyle}>"look, i like you kid, but you're not ready for the big city yet.
          i'll even buy you a ticket back to johnstown."</span><br /><br /><br />
          <span style={{...biggerFontSize, ...boldStyle}}>"i can't go back to johnstown, my father will make me get another
          kind of job, and i'm a great drummer, and i'm gonna be a star, and i'm not leaving your office until
          you find me a gig!."</span><br /><br /><br />
          <span style={boldStyle}>"kid, i never even heard you play, how can i get you a gig?"</span><br /><br /><br />
          <span style={{...biggerFontSize, ...boldStyle}}>"here's a record i made, mr. green."</span><br /><br /><br />
          <span style={boldStyle}>"good playing kid! you're real fast.  but you really should do something with that hair, it's way too long,
          and i don't really know where i could book you with all that hair.  look kid, i like you, you got heart,
          and maybe you might be a star, but come back in a few years.  I'm gonna do you a favor cause i like you,
          how about a ticket back to anywhere in pennsylvania?"</span><br />
          (side note re: hair - east coast was into pompadours)<br /><br />
          the young drummer was about to split when in walks danny apollinar, whose trio just
          finished with a USO tour in nam.<br /><br />
          <span style={boldStyle}>"sid, you gotta help me, my drummer and bass player just split, and i got a gig in ft.
          lauderdale in two days!"</span><br /><br /><br />
          <span style={boldStyle}>"danny, i gotta drummer right here! he's great, and he can play anything!"</span><br /><br /><br />
          <span style={boldStyle}>"can you play "the lady is a tramp" and do you use brushes?"</span><br /><br /><br />
          <span style={{...biggerFontSize2, ...boldStyle}}>"i can play anything".</span><br />
          ("i figured i could learn along the way")<br /><br />
          <span style={boldStyle}>"great, what's your name,?"</span><br /><br />
          <span style={boldStyle}>"joey"</span><br /><br />
          <span style={boldStyle}>"joey, 200 bucks a week plus room and board."</span><br /><br /><br />
          <span style={{...biggerFontSize, ...boldStyle}}>"yeah, i'll take it...., mr. green, what's your commission?"</span><br /><br /><br />
          <span style={boldStyle}>"kid, this one's on me, GO BE A STAR!"</span><br /><br /><br />
          the next day the the drums got loaded in a subway to get to the 30 show tune rehearsal,
          then it was on to a plane and off to florida for<br />
          <span style={{...biggerFontSize, ...boldStyle}}>"my first tour."</span><br /><br />
          when the tour ended, figuring dad would be happy to see him -  <br /><br />
          <span style={{...biggerFontSize, ...boldStyle}}>"i proved i was a pro, so i headed home."<br />
          ("I figured I'd go show off the tan")</span><br />
        </p>

        <br />

            <p>
              <HtmlImage id={"johnstown_music_society"}
                         src={MusicSociety}
                         alt={""}
                         style={{width: '334px'}} />
            </p>

            <br />

            <p>
              while home, some calls came in to do some gigs;  it was back to rock n' roll - dick clark caravan of stars,
              shangri'la's, billy stewart (summertime), the supremes, donald jenkins and the delighters (elephant walk),
              the shirelles...
            </p>

            <p>
              age 21 -got a call from sonny di'nunzio of the fenways, the “it” band of pittsburgh.<br /><br />
              <span style={boldStyle}>"said he remembered me from a battle of the bands, and would i like to come
              to pittsburgh?"</span><br /><br />
              <span style={boldStyle}>"sure!"</span><br /><br />
              for the next year, Joey cut some singles with the fenways and played seven nights a week, opening shows for
              the rolling stones, dave clark 5, shangri' la's, lee dorsey (working in a coal mine), lou christie, chad and jeremy,
              jimmy beaumont and the skyliner's, among others.
            </p>

            <p>
              <HtmlImage id={"the_fenways"}
                         src={TheFenways}
                         alt={""}
                         style={{width: '309px'}} />
            </p>

            <p>
              age 22 -  an aspiring hitman/guitar player/songwriter friend that joey had met in pittsburgh, called one day and
              said <span style={boldStyle}>"lets go out west."</span><br /><br />
              <span style={boldStyle}>"when louie called i was ready to move on towards fame and fortune."</span><br /><br />
              so, in the spring of 1966,  in a new mercedes of questionable source , joey and louie head for california.<br /><br />
              <span style={boldStyle}>"besides, i liked the idea of driving out there in a new mercedes."</span><br /><br />
              <span style={boldStyle}>JOKIN’ AND TOKIN’</span>, it was Route 66 all the way……..until somewhere in Oklahoma<br /><br />
              <span style={boldStyle}>“where all you saw for miles was tumbleweed, I looked out the back window and saw the drum trailer going
              bumpity bump back the other way.”</span><br /><br />
              <span style={boldStyle}>“WELL, THERE GOES MY DRUMS”.</span><br /><br />
              While Louie spent 6 hours looking for a welder,<br /><br />
              <span style={{...biggerFontSize, ...boldStyle}}>“I was smoking a doobie watching the Oklahoma sunset, thinking, mmm,<br />
              SO YOU WANNA TO BE A STAR?”</span><br /><br />
              Louie found a farmer who happened to be a welder that soon got them rolling again.  Joey got to drive a
              car for the first time.<br /><br />
              <span style={boldStyle}>WHILE BEHIND THE WHEEL OF THE NEW MERCEDES, ALL SECOND THOUGHTS DISAPPEARED.<br /><br />
              All in all, four days and a lid out of Pittsburgh they ended up in a parking lot behind sherri’s
              lounge on sunset boulevard in Hollywood, California, contemplating which way to stardom.
              This was the spring of 1966.</span>
            </p>

            <br />

            <p>
              THE L.A. DAZE ARE COMING SOON<br /><br />
              TO BE CONTINUED<br />
            </p>

          </div>

      }
      {
        (error) ? <p>Error loading So you want to be a drummer Page </p> : ''
      }

    </div>

  );

};



class Drummer extends Component {

    /* Constructor */
    constructor(props) {
        // Initialize parent
        super(props);

        // set default state for component
        this.state = {
            loading: false,
            error: null
        };
        console.log('so you want to be a drummer constructor');
    }

    /* component will mount react life cycle function */
    componentWillMount(){

    }

    /* component will update react life cycle function  */
    componentWillUpdate() {

    }

    /* component did update react life cycle function  */
    componentDidMount() {

    }

    /* component render function  */
    render() {
        // load state variables
        const { loading, error } = this.state;

        return(

            <Switch>

                <Route exact path="/drummer"
                    render={() => (
                        <Main loading={loading} error={error} />
                    )}
                />

            </Switch>

        );

    }

};

const DrummerCntr = connect(mapStateToProps, mapDispatchToProps)(Drummer);

export default DrummerCntr;
