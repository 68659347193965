/* error404.js - a react http 404 error ui component */

import { Component } from 'react';

class Error404 extends Component {

    render() {
        return(
            <div className="404-error">
                <h1>Whoops cannot find resource {this.props.location.pathname}</h1>
            </div>
        );
    }

}

export default Error404;
