/* Biography.js - a react home component */

/* Import Libraries */
import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Switch, NavLink } from 'react-router-dom';


/* Import Components */
import NavMenu from '../menus/NavMenu';
import HtmlImage from '../common/HtmlImage';


/* Import Redux Actions */


/* Import Stylesheets */
import '../../stylesheets/components/pages/biography.css';

/* Import images */
import Joey2 from '../../images/joey002.jpg';

/* Redux functions */
const mapStateToProps = state => {
    return {

    };
};

const mapDispatchToProps = dispatch => {
    return {

    };
};

/* Helper Functions */


/* Inline components */

const Main = ({ loading, error }) => {

  const boldStyle = {
    'font-weight': 'bold'
  };

  return(
    <div className="biography">

      <NavMenu />

      {
        (loading) ?
          <span>Loading Biography Page</span> :

          <div className="content">

              <p>
                <HtmlImage id={"joey2picture-2"}
                           src={Joey2}
                           alt={""}
                           style={{width: '400px'}} />
              </p>

              <p>June 27th 1945 - June 4th 2013</p>

              <p>
                Though best known for his work with Jefferson Airplane, Electric Hot Tuna, and Jefferson Starship,
                Joey Covington (born Joseph Michno) had a long career starting at age 10 as a self taught drummer/percussionist.
                Joey recorded on over 22 albums, of which 16 went gold and platinum. Along his musical journey he became a record producer
                and an award winning songwriter.
              </p>

              <p>
                Joey became a professional drummer at age 10, starting in polka bands, strip clubs, rock bands, and any situation
                to gain experience in his hometown - Johnstown, Pennsylvania. The link “So You Wanna Be A Drummer” highlights his
                early career memories.
              </p>

              <p>
                In the early to mid-’60s, he was playing with bands that opened shows for the Rolling Stones, Dave Clark 5,
                Shangri' La's, Lee Dorsey (“Working In A Coal Mine”), Lou Christie, Chad and Jeremy, Jimmy Beaumont and the
                Skyliner's, and he had a stint playing drums backing up Dick Clark's Caravan of Stars featuring acts such as The Supremes.
              </p>

              <p>
                Joey headed west to Los Angeles in 1966. He was quickly discovered and produced by famed producer/songwriter
                Kim Fowley as a singing drummer. The single released was a cover of The Who's “Boris The Spider” with
                “I'll Do Better Next Time” on the B side (the first song Joey ever wrote). He co-formed several bands in
                Los Angeles during that period. Tsong with Mickey Rooney Jr., and a yet to be named band with Papa John Creach,
                Jimmy Greenspoon, and Joe Shermie. Papa John later was brought in by Joey to Jefferson Airplane, Hot Tuna,
                Jefferson Starship and then went on to have a long solo recording career. Jimmy & Joe went on to become members
                of Three Dog Night.
              </p>

              <p>
                Joey was brought to San Francisco in mid 1968 by Jefferson Airplane founder Marty Balin. A member of Jefferson Airplane,
                Joey at first co-formed Hot Tuna with Jefferson Airplane members Jorma Kaukonen and Jack Casady in late 1968, with
                Hot Tuna opening shows for the Airplane. In early 1969 Joey was playing drums in both Hot Tuna and augmenting, then
                ultimately replacing Airplane drummer Spencer Dryden. Joey's first recordings with Jefferson Airplane can be heard on the
                classic 1969 album, <span style={boldStyle}>Volunteers</span>. On the group’s <span style={boldStyle}>Bark</span>
                album, Joey wrote and sang, “Pretty As You Feel”, the fourth and last hit song of Jefferson Airplane. Also on Bark,
                he wrote and sang "Thunk", which became a college anthem.
              </p>

              <p>
                Joey left Jefferson Airplane, Hot Tuna, & Jefferson Starship by mid 1972 to record his solo album Fat Fandango
                for the Airplane's record label, Grunt Records. The single “Your Heart Is My Heart” and the album were released
                worldwide resulting in high album sales and critical acclaim. Never quite leaving the Airplane family, he co-wrote
                the award winning and multi-platinum hit in 1976 for Jefferson Starship, “With Your Love” and he wrote Papa John Creach's
                only top 40 hit “Janitor Drivers A Cadillac”.
              </p>

              <p>
                Joey remained musically active throughout his life: 1970's through the 1980's he formed San Francisco All-Stars and
                toured with various lineups. He also toured with Quicksilver Messenger Service. The 1990's and 2000's brought Joey
                back down to Los Angeles where he recorded and toured with various All-Star Line Ups. All throughout his career he
                gave back to humanity by making numerous appearances performing for charity.
              </p>

              <p>
                A “time out” to Palm Springs, California in 2010 turned out to be 3 years.
                Just as Joey and LAuren were packing up to move back to Los Angeles, on June 4, 2013 while driving home,
                he lost control of his car and suffered a fatal accident. Initial Police statements to the media that he wasn't
                wearing a seat belt were demonstrated to be false when photos of the seat belt showed indentation marks
                made by the car's airbag when it deployed.
              </p>

              <p>
                Joey was a colorful outgoing personality, leaving more than a lasting impression on many.
                He will be sorely missed by those that loved him, his friends, and his fans.
              </p>

              <p>
                Sister Sandy's last birthday card to Joey, June 2013:
                “Now that your birthday has come and gone I can honestly say you are causing the same racket in heaven
                as you did when we were young in our early teens.”
              </p>

              <p>LAuren, Sean & Peter Stogel & The Michno Family</p>

          </div>

      }
      {
        (error) ? <p>Error loading Biography Page </p> : ''
      }

    </div>

  );

};


class Biography extends Component {

    /* Constructor */
    constructor(props) {
        // Initialize parent
        super(props);

        // set default state for component
        this.state = {
            loading: false,
            error: null
        };
        console.log('biography constructor');
    }

    /* component will mount react life cycle function */
    componentWillMount(){

    }

    /* component will update react life cycle function  */
    componentWillUpdate() {

    }

    /* component did update react life cycle function  */
    componentDidMount() {

    }

    /* component render function  */
    render() {
        // load state variables
        const { loading, error } = this.state;

        return(

            <Switch>

                <Route exact path="/biography"
                    render={() => (
                        <Main loading={loading} error={error} />
                    )}
                />

            </Switch>

        );

    }

};

const BiographyCntr = connect(mapStateToProps, mapDispatchToProps)(Biography);

export default BiographyCntr;
