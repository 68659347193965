/* App.js - the base react component */


/* Import libraries */
import { Route, Switch } from 'react-router-dom';

/* Import Components */
import Page_template from './ui/pageTemplate';
import Home from './pages/Home';
import Biography from './pages/Biography';
import Discography from './pages/Discography';
import Drummer from './pages/Drummer';
import Girlfriend from './pages/Girlfriend';
import IThunk from './pages/IThunk';
import Links from './pages/Links';
import Songs from './pages/Songs';
import Uncensored from './pages/Uncensored';


/* Import UI */
import Error404 from './ui/error404';

/* Import Stylesheets */



const App = () =>

    <Page_template>

        <Switch>

            <Route path="/biography" component={Biography} />
            <Route path="/discography" component={Discography} />
            <Route path="/drummer" component={Drummer} />
            <Route path="/girlfriend" component={Girlfriend} />
            <Route path="/ithunk" component={IThunk} />
            <Route path="/links" component={Links} />
            <Route path="/songs" component={Songs} />
            <Route path="/uncensored" component={Uncensored} />
            <Route path="/" component={Home} />
            <Route component={Error404} />

        </Switch>

    </Page_template>;

export default App;
