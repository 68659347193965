/* headerer.js - a react header ui component */

import { Component } from 'react';

/* Import stylesheets */
import '../../stylesheets/ui/header.css';

class Header extends Component {



  render() {

    const boldStyle = {
      'font-weight': 'bold'
    };
      
    return(
      <header className="main_header">

        <h1>Joey Covington</h1>
        <h3 style={boldStyle}>Percussionist, Singer, Songwriter, Producer</h3>

      </header>
    );
  }
}

export default Header;
