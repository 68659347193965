/* Uncensored.js - a react uncensored component */

/* Import Libraries */
import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Switch, NavLink } from 'react-router-dom';


/* Import Components */
import NavMenu from '../menus/NavMenu';


/* Import Redux Actions */


/* Import Stylesheets */
import '../../stylesheets/components/pages/uncensored.css';


/* Import images */


/* Redux functions */
const mapStateToProps = state => {
    return {

    };
};

const mapDispatchToProps = dispatch => {
    return {

    };
};

/* Helper Functions */


/* Inline components */

const Main = ({ loading, error }) => {

  const boldStyle = {
    'font-weight': 'bold'
  };

  const centerStyle = {
    'text-align': 'center'
  };

  return(
    <div className="uncensored">

      <NavMenu />

      {
        (loading) ?
          <span>Loading Uncensored Page</span> :

          <div className="content">

            <br />

            <h2 style={{...boldStyle, ...centerStyle}}>TOTALLY UNCENSORED & UNDER THE RADAR MAIL</h2>

            <p>
              Well, we tried the message board and my webmaster hit tilt when it came to deleting all the spam….. so..<br />
              <a href="mailto:bandinusa@aol.com">click here</a> to send us your TOTALLY UNCENSORED MAIL
              (if you don’t want your email or email address posted let us know before it’s posted !!!!
            </p>

            <br />

            <p className="email-message">
              <span style={boldStyle}>Date: 02/02/08</span><br />
              Sensbachtal@mail.ru<br />
              Just wanted to say hello to everyone    much to read an learn here, I’m sure I will enjoy !
            </p>

            <br />

            <p className="email-message">
              <span style={boldStyle}>Date: 10/19/07</span>
              Hey Joey……Jimmy Greenspoon
              Came across your page by accident.  It’s been way too long my friend.  The internet is so great to find and keep in
              touch with old friends and band mates.  I’m still out there with THREE DOG.  40 years come 2008.
              Who would have thought.  Hell, who would have thought I’d still be alive.  Get in touch with me.
              Stay well amigo…….<br />
              JG
            </p>

            <p className="email-response">
              Now I know why you stopped showing for rehearsals at the Lauren Canyon House !!!!!!!!!!!!!!
              Will email you……great to hear from you.<br />
              Joey
            </p>

            <br />

            <p className="email-message">
              <span style={boldStyle}>Date: 05/22/07  & 5/23/07</span><br />
              From YOUR SISTER SANDY

              Joey,

              Just thought I would remind you that your job is still waiting for you when you run out of money.
              &; Oh, you've already done that. & ;Well what are you waiting for, Walmart is calling you.<br />
              The name and place may have been changed to protect the innocent.<br />
              Sandy<br />
              This is an actual job application that a 60 year old senior citizen<br />
              submitted to Wal-Mart in California. They hired him because he was so funny.....<br />
              NAME: Joey Covington, Sr (Grumpy Bastard)<br />
              SEX: Not lately, but I am looking for the right woman (or at least one who will cooperate)<br />
              DESIRED POSITION: Company's President or Vice President. But seriously, whatever's available.
              If I was in a position to be picky, I wouldn't be applying here in the first place?<br />
              DESIRED SALARY: $185,000 a year plus stock options and a Michael Ovitz style severance package.
              If that's not possible, make an offer and we can haggle.<br />
              EDUCATION: Yes.<br />
              LAST POSITION HELD: Target for middle management hostility.<br />
              PREVIOUS SALARY: A lot less than I'm worth.<br />
              MOST NOTABLE ACHIEVEMENT: My incredible collection of stolen pens and post-it notes.<br />
              REASON FOR LEAVING: It sucked.<br />
              HOURS AVAILABLE TO WORK: Any.<br />
              PREFERRED HOURS: 1:30-3:30 p.m. Monday, Tuesday, and Thursday.<br />
              DO YOU HAVE ANY SPECIAL SKILLS?: Yes, but they're better suited to a more intimate environment.<br />
              MAY WE CONTACT YOUR CURRENT EMPLOYER?: If I had one, would I be here?<br />
              DO YOU HAVE ANY PHYSICAL CONDITIONS THAT WOULD PROHIBIT YOU FROM LIFTING UP TO 50 lbs.?: Of what?<br />
              DO YOU HAVE A CAR?: I think the more appropriate question here would be &quote; “Do you have a car that runs?”<br />
              HAVE YOU RECEIVED ANY SPECIAL AWARDS OR RECOGNITION?: I may already be a winner of the Publishers
              Clearing House Sweepstakes, so they tell me.<br />
              DO YOU SMOKE?: On the job - no! On my breaks - yes!<br />
              WHAT WOULD YOU LIKE TO BE DOING IN FIVE YEARS?: Living in the Bahamas with a fabulously wealthy dumb
              sexy blonde supermodel who thinks I'm the greatest thing since sliced bread. Actually,
              I'd like to be doing that now.<br />
              NEAREST RELATIVE....miles away<br />
              DO YOU CERTIFY THAT THE ABOVE IS TRUE AND COMPLETE TO THE BEST OF YOUR KNOWLEDGE?: Oh yes, absolutely.<br />
              Sandy,
            </p>

            <p className="email-response">
              No need to worry...........true, my money's run out, but I get senior discounts at IHOP and Denny's
              restaurant's. And I have plenty of money to tip all the waiters and waitress's with the the Birthday
              bucks you send me. And; I always tell them this is on my  older sister!<br />
              Hey, is your granddaughter Lauren drumming in a Polka band yet?<br />
              The old geezer<br />
              P.S. Time has proven dad right....................no medical or social security for a musician..........<br />
              Guess they spent it all on the Hubble telescope!!!!!!  (remember ?, that telescope they sent up
              with bad lenses when you  worked for NASA !!<br />
            </p>

            <p className="email-message">
              Hey Joey,<br />
              Lauren is still drumming at School, she is good. Hasn't joined a Polka Band, not sure if she has even heard of
              the Polka. Remember she is not a Pennsylvania bred girl. Massachusetts girl, she likes the band Green Day,
              you probably never heard of them, right? Young group you probably don't follow. And; By the way I dig the
              IHOP &  Denny scene, we are going to be getting an IHOP in our little farm town here in Delaware before
              the years ends. And; When I go for my Senior Discount meals, I will think of you in CA doing the same thing.
              Unlike you though, I will not have any spare money to tip. So I may have to curtail sending you the buck
              you usually get on your birthday, so I will be able to tip our waiters & waitresses.<br />
              Yep, Dad was right about the Medical and SS payments.  Guess the system figured if you were really
              good enough to make tons of money, you would invest in at least lots of properties like the Stars
              do (did ya) No! So don't complain.  And, if you were not good, you'd quit and get a job that
              offered the Bennies.<br />
              Hubble is doing a great job for you it found a Giant Black Hole that your money must have fallen into.
              Only one problem it cannot identify whether it's your money or Whitney Houstons, so even if it is yours,
              minority stills wins out in this case. Black is beautiful brother and your white butt gets the boot and
              Whitney gets the dough. So cheer up and start singing the Blues, it may come back. Never give up and
              never look back.<br />
              Your Smart Sister
            </p>

            <br />

            <p className="email-message">
              <span style={boldStyle}>Date :05/01/07</span><br />
              From:  Cybil Durango<br />
              Dear Joey,<br />
              I think you're one in a trillion. I bought your Fat Fandango LP in the 70's simply because I liked a lot
              &quot;Thunk&quot; and &quot;Pretty As You Feel&quot;. It's one record in a trillion too. And wonderful
              cover! I still treasure it in my vinyl collection. How nice to know you're alive and well. A big hug
              from a spanish journalist-musician-lovingfather.<br />
              Cybil.
            </p>

            <p className="email-response">
              Nice to hear from you . Yes I'm alive and well and still making music!! Sempre la musica!<br />
              Joey
            </p>

            <br />

            <p className="email-message">
              <span style={boldStyle}>Date: 03-14-07</span><br />
              Hey Joey<br />
              This is Dan Slick, Sharen Slick's little brother. Just wanted to touch base
              with you and tell you I've followed your career like forever.  I was the GM
              for Panasonic/Technics for a long time and knew your buddy Pete Sears.  He
              was one of our endorsers for our pianos.   I still remember the day you came
              into Rab's barber shop with your bongos and sat in the corner and played.
              Rab asked if you wanted a haircut and you said "No man, I just thought you'd
              like some good music".<br />
              I also remember how you used to get all the Conemaugh High School drummers
              around you in a circle and play like crazy.  You inspired me to become a
              musician and I've been playing keys and gigging all my life.  Just thought
              you might like hearing from someone who knew you before you became a rock
              star. I live just south of NY City in New Jersey.  If you ever get to the
              East Coast, feel free to stop in.  Hope all is well with you and keep on
              rockin..<br />
              Peace<br />
              Dan Slick
            </p>

            <p className="email-response">

            </p>

            <br />

            <p className="email-message">
              <span style={boldStyle}>Date: 06-27-06</span><br />
              Joey<br />
              Blast from the past time.<br />
              It's been almost 36 years since you demonstrated Impakt Percussion
              Synthesizers in Portland Oregon. Who remembers that!
              I do. And I remember a cat who glimpsed the future and embraced it. You
              may remember Steve Lammé, he invented electronic percussion.
              Steve was my Dad. He sure remembered you, your enthusiasm and
              willingness to explore groundbreaking technology.
              And I remember you as well you helped me realize what was happening and
              what was not.<br />
              Never to late to say THANKS.<br />
              Always a fan.<br />
              Your Friend.<br />
              Étienne Lammé
            </p>

            <p className="email-response">

            </p>

            <br />

            <p className="email-message">
              <span style={boldStyle}>Date: 06-21-06</span><br />
              SUB:  Finland and Friends Benefit Jam<br />
              I hope this would reach Joey.<br />
              I just wanted to extend to him my warmest thanks for his contribution to our Finland and Friends Benefit
              Jam last night at the residence of Finland's Consul General. Joey's presence was a great honor for me
              personally and our guests definitely appreciated his being there.<br />
              Thank you! Because of people like you I really love my job!<br />
              Best regards,<br />
              Manu Virtamo<br />
              Consul General of Finland
            </p>

            <p className="email-response">
              MusiCares is a great organization and I was happy to be a part of it.  And it was great to jam with you<br />
              Joey
            </p>

            <br />

            <p className="email-message">
              <span style={boldStyle}>Date:  06-25-05</span><br />
              Subj:  RE: 1967<br />
              Joey<br />
              Glad to see you made it in life. My girlfriend Denise and I used to hang out at your house in the canyons of LA.
              Laurel that is. We were just youngin's. You were living with your girlfriend and we spent many hours hanging
              out talking and listening to music on the floor. Always a gentleman!!<br />
              Thanks for the early memories<br />
              Katy
            </p>

            <p className="email-response">
              Someone finally remembers me as a gentleman……That’s nice to hear.  I’ve always been called a “bad boy”.<br />
              Joey
            </p>

            <br />

            <p className="email-message">
              <span style={boldStyle}>Date:  06-05-01</span><br />
              Subj:  RE:  Benefit Concert January 2006 for Ocean Springs, Mississippi<br />
              On a personal note, could you please pass along my thank you to Joey for his help on the concert?
              The benefit raised over $67,000 and the money was distributed to Ocean Springs about 3 weeks ago.
              My own family (my mom and dad, brother, 4 cousins, and my aunt) all lost their homes to Katrina in
              the Biloxi/Ocean Springs area. I've been back 4 times and each time the people in the area are so
              appreciative. Often there is no way to say thank you to those that have helped, but now I can tell
              Joey for them.<br />
              Best regards,<br />
              Belinda<br />
               Belinda Serata Photography
            </p>

            <p className="email-response">

            </p>

            <br />

            <p className="email-message">
              <span style={boldStyle}>Date:  98-03-14 10:21:16 EST</span><br />
              From:  SLOOPJOHNB<br />
              Subj:  RE:  FAT FANDANGO REVIEW<br />
              To: 2400FULTON@NETSPACE.ORG<br />
              cc:  BANDINUSA@AOL.COM<br />
              Well hey!  I waited 27 years to hear Joey Covington's solo (1973) "Fat Fandango" and since it's not that
              easy to come by, I thought I'd review it.  My vinyl copy came from Princeton Record Exchange in Princeton,
              NJ and it cost $1.99.  The cover is less than optimum condition, but the Grunt flexi vinyl is in pristine
              condition. The sound is good. Let's face it, where else are we going to get this review?<br />
              "Joe E. Covington's Fat Fandango", Grunt Records BFL1-0149<br />
              Cover:  Gatefold cover featuring many strange Chagall-inspired band potraits (?) by an artist named Montanes.
              Very loopy looking and highly indicative of the musical contents.  He appears on the front as a two faced blond
              surf-dude-looking apparition.
              Tracks:<br />
              Side A "Your Heart Is My Heart";  "Country Girl";  "Moonbeam";  "MaMa Neptune"<br />
              Side B "Miss Unaverse";  "Hideout (Is A Crook's Best Friend)";  "Vapor Lady"<br />
              All tracks written and arranged by Joey Covington; "Your Heart Is My Heart" co-written with the band.
              Mauriceman and Joe on the boards.Recorded at the always fabulous Wally Heider Studio in SF.<br />
              The band: (Fat Fandango? Joe E. Covington (vocals,drums) Senator Patrick Craig (keyboards) Stevie Midnite (guitar)
              Jack Pendergast (bass) *this is definitely not Jack Casady*<br />
              "Your Heart is My Heart" Typical elliptical Covington lyric. The soul shuffle with high falsetto's from Joe
              surprised me.  This is a pretty good Philly soul simulacrum with a strong blast of daffiness.
              Nice back-up harmonies in spots.  The song jets into a double time ending. Not a very normal song.<br />
              "County Girl"  This is only very superficially reminiscent of the Neil Young paean of the same name.
              Joe's plaintif soul vocal (again) edges into hysteria as he asks "country girl let me be your country boy"
              (duh) as the song veers from sweet pastoral to electric raveup. So far Joe is definitely blazing uncharted
              territory here.<br />
              "Moonbeam"  Imagine my intrigue as the needle hit the groove on a Covington opus named "Moonbeam".
              This, of course is a doo-wop vocal which is sort of a very insincere (in a funny way) "In the Still of the Night".
              Hear to believe.  This one's going on the highlight tape for sure. Like Maurice Williams on Angel Dust.<br />
              "MaMa Neptune" Suitable watery keyboards and echoey vocals kick off this opus, which struck me as a sort of
              lounge music for maniacs.  Sample lyric: "Let it flow oh woe ooo whoa".<br />
              "Miss Unaverse"  For me, Joe really hit his stride on the LP right here.  More doo-wop dementia with our man
              pining for an unattainable "around the world" (snark,snark) with some young lovely.  The doo wop gives way to
              an Iron Butterfly/Traffic segue to a really weird ending featuring lyrics like "the yellow sun's my brother,
              the universe my lover".  I really grokked this one.<br />
              "Hideout"  Nice romp with some quasi-movie theme music on a song about robbing an armoured car that somehow
              winds up with the singer wanting his house to be turned into an armored car (I think).<br />
              "Vapor Lady"  This is a fitting closer, 8:08 of music about a "woman of sunlight" which sounds like early,
              lobotomized Todd Rundgren.  It is nice and discursive in parts and I found it to be musically very grandiose
              and yet at the same time whimsical.  Sample lyric: "when she glaciates she shakes the sky-yi-yi-yi-".
              (Whoa! Uh, what was tha-at, man?)<br />
              General Comments:  1. Holy Shit!  2. I like this platter very much, thank you.  It's about as far removed from
              the JA sound as first drummer Skip Spence's solo, "Oar" though in a totally different though equally strange
              direction.  (More of a soul-psych flake-out as compared to Skip's folk-psyche flake-out). 3. Mellow, whimsical,
              flaky, light throughout.  4.  If you liked "Thunk" from "Bark", check this out.  If you didn't, don't.
              5.  The combo playing here is solid throughout.  The keyboardist (hammond organ in crucial passages) in particular,
              is good.  (The Senator!)  6.  These are perhaps the strangest arrangements this side of Syd Barrett.
              You go, Joe!  7.  In general, this defies analysis and comparison.  It is more light-hearted than almost
              anything you are likely to hear these days, including Hanson.  (They sound pretty calculated next to these guys).
              8.  Joe's singing (while definitely not Marty) is good for what it is:  very strange soul singing and crooning.
              He's definitely onto something here.<br />
              So there it is.  Very nice.  I shall be making tapes for some of my lonny tune psych music friends and I will
              be anxious to get their stunned reactions.<br />
              Sloop John B<br />
              "Doing nothing is as important as doing something."
            </p>

            <p className="email-response">
              Your review of Fat Fandango was a real Thunker!  Isn't Ma Ma Neptune supposed to be real liquidy?
              What's grokked mean?  I like this word.  Is it like my parrot grokked?  Or I grokked my girlfriend?
              Is a lobo Todd Rundgren a good state of mind?  What's soul-psyche flake-out?  Is Flake-out kind of like
              flame out on stealth jet technology, or flake-out like eating cornflakes with Oxydol?
              Top Ten Answers For One Who Loves Thunk:  1.  You can sell Fat Fandango to a guy in Pittsburgh for 30.00
              and net 28.01.  2.  Jose Montanes got 2500.00 to paint my LP.  He went to school with Pablo P.  as young boys.
              Before they were famous painters they had to learn how to spell "Paint".  Montanes' work is worldwide in
              museums.  3.  I have many faces but have never surfed in my life.  I like the surf-dude apparition kidu.
              5.  I call Fat Fandango "Psychedelic R&B".  6.  Hideout is about wanting an armored car.  Listen closer you'll
              find I don't want the money, I just want the car to put in my house just to hang out in.
              7.  When she glaciates means its a pregnant cloud ready to give birth to millions of water droplet babies.
              You can't sail a ship without water.  More to be revealed,<br />
              Joey
            </p>

            <br />

            <p className="email-message">
              <span style={boldStyle}>DATE:  98-03-14 11:05:08 EST</span><br />
              Subj:  For Joey Covington<br />
              Dear BANDINUSA,<br />
              I was so surprised to find web page for Joey Covington. I enjoyed the page and hope you can foward my letter
              to him at his E-Mail.<br />
              Very Gratefully Yours,<br />
              Ely  R.<br />
              Dear Mr. Covington<br />
              Here I am sitting on Fourth Street in Conemaugh and out of the blue I decided to try to reach you.  Very strange.
              But it is even more strange.  The way this started was that I am a very big fan of Roger Troy.  That in itself is
              strange because I think that hardly anybody knows of Roger Troy and surely no one in Conemaugh (or Johnstown or
              Pittsburgh for that matter) knows of Roger.  Certainly no one listens to his music.  I was looking for information
              on Roger on the Web when Yours and Nick Gravenites names came up due to your work on the Blue Star LP.
              When I happened to find your web site it was very interesting.  You seem to have good spirit.<br />
              I was surprised to find out that you used to work with the Fenways.  You see, I am from Pittsburgh and only came
              to Conemaugh in the late 60's when I was a Jr. in high school.  Because I was growing my hair long and hanging with
              freaks in Pgh. my mother became frightened and thought that I was becoming homosexual.  She did the only thing she
              knew how to do, she sent me to Conemaugh to live with her mother.  I think you may have known my grandmother.
              She was the cleaning lady at the old Conemaugh High School.  She was a very little lady.
              Her name was Katherine Holub and she is part of the Strank family.  Now that I think of it, and I can't remember why,
              she mentioned your name.  She called you the Michno boy.  But I digress.  When I was young and living in Pittsburgh,
              I think that the Fenways may have opened for the Dave Clark 5 at the Civic Arena in around 1964 or 1965.  Anyway,
              I was only in eigth grade then, but I went to that concert.  As I recall, Lou Cristy was also on the bill.
              If I am correct, my memory is much better than I imagined.  If you were on drums, I never would have guessed
              that someone from Conemaugh would have been in the group.  By the way, maybe you are aware that Sonny died in
              the 70's.  I think that he died in an automobile accident.<br />
              I did not tell you that I too am a musician.  The reason I mention it is because my musical partner, who lives in
              Ventura, also worked with Sonny.  For a few years in the 70's my partner played guitar in the Racket Squad.<br />
              My first experience with Roger Troy was in the early 70's in Boston.  At that time I was working in a band at the
              Rat which is across the street from the Kenmore Club, where we had been performing the previous week.  Anyway Mike
              Bloomfield was there and so on our breaks we would run over to the Kenmore Club to catch his show.  I did not know
              it then, but it was Roger on bass and vocals.  I found out that Roger died in the early 90's.  I was so disappointed.
              I don't know much about him, but I felt such a sense of loss.  I don't have much music by Roger, just the few
              Bloomfield cuts, one Electric Flag album and I managed to find a promo copy of a Roger Troy solo record on RCA.
              I wish I could find more music by him.  I think I read somewhere that he worked out of Ohio and was a part of
              King Records or something like that.  He was also a wonderful songwriter.  I should not go on so about Roger
              Troy in a letter to you.<br />
              Funny thing is, I am embarrassed to say that I am very unfamiliar with your work.  Somehow in the 60's I missed
              the Airplane and was never very aware of them.  I don't know why.  For instance I really liked Moby Grape
              (and Skip Spence's mustache.  In fact all of my life I have worn a Skip Spence mustache.  Weird the influence of
              people in our lives.  I think he may have also played drums with the Airplane???  I also really liked the
              Electric Flag (I recently found a couple of CDs by the Flag).  I liked horns and so I also liked BS&T and I loved the
              soul music of the time (Memphis Stax).  That is what I liked to sing.  I have been a singer.  I liked Janis too,
              but I missed the Airplane somehow.  I remember thinking that Jack Cassidy was very cool though.  I guess it was
              the glasses.  I don't even know if he is much of a bass player, but I imagine he must be.  I know that guitarist
              (with the funny name) is a very good player.  Actually I also have a Joey Covington album on Grunt.
              It is called Fat Fandango.  I found it as a cut out somewhere.  I haven't played it yet though because I do not
              have a functional turntable.<br />
              I have one more Joey Covington story to tell.  One day in 1968 or 69, I remember seeing you at Angelo's station.
              I think you were driving a Trans Am.  You looked good.  There weren't many long hairs around in those days and
              especially not around here.  I remember just watching you and then somebody told me who you were.
              Well that's it.<br />
              I don't even know why I worte.  I guess your connection with Conemaugh and Roger means something to me.
              It is surprising the number of great players from this area.  I don't know if you are aware of Chuck Kerrigan.
              He is a drummer from this area.  He works on our sessions (I write and record a lot).  He is just wonderful.
              Really in the league with Joe Morrelo (who was his teacher for many years) or Louis Belson.  He wrote a few books
              for Mel Bay also.  Anyway, my partner too is great.  It is my pleasure to know him.  He is not only a guitar player.
              He studied for a while at Berkeley (as did Chuck Kerrigan) and he writes all of our horn parts (as I said I love horns).
              I think that you would be very surprised at the quality of music that we make.  Just a couple of local guys making great
              music.  By the way we have a great sax/blues piano player who plays on many of our recordings.  He used to be on keys
              and sax in the group America and these days he plays in my partner's lounge group.  Sometimes up, sometimes down.
              Sun faced Buddha/Moon faced Buddha.<br />
              Good talking to you.  I hope this finds its way to you.<br />
              Ely R.
            </p>

            <p className="email-response">
              I always thought Conemaugh was a place to leave!  I think your grandmother caught me and my girlfriend making
              out behind the stage in the high school theater.  Yes, I was with the Fenways when they opened for the Dave
              Clark 5.  I knew him as "Troy".  You might want to sign up for the JA List Serv (on the links page) and ask
              if anyone has info on his discography and you can catch up on Jefferson Airplane.  And, if anybody reading
              this has any info on Troy, lets help him out.<br/>
              Joey
            </p>

            <br />

            <p className="email-message">
              <span style={boldStyle}>Date:  98-03-25 21:41:05 EST</span><br/>
              Subj:  Fellow musician (Pittsburgh area)<br/>
              I have enjoyed your drumming for years (big-time JA fan), but I had NO idea you were part of the Pittsburgh
              scene-i.e. the fenways (And, I believe, later on as Jubilee Recording Stars The Racket Squad.<br/>
              Do you remember the Swanp Rats CO & CE cover version of the Rolling Stones "It Ain't Easy.  Did you ever
              do the Clark Race show.<br/>
              Anyway, I later played bass in that whole Pittsburgh / Cleveland / Columbus circuit, but got burnt out on the road.
              I am working on songwriting projects and local jazz gigs.<br/>
              Anyway, sorry you didn't get proper recognition at the Hall of Fame Award.  You certainly were an important
              part of the Jefferson Airplane AND Hot Tuna.  I even have your "Fat Fandango album!<br/>
              Anyway, great seeing your page and keep on playing.<br/>
              Dave Molnar
            </p>

            <p className="email-response">
              I did the Clark Race show.  He gave the Fenways "Hanky Panky", which Sonny turned down.  I don't know the Swamp
              Rats or The Racket Squad.  Thanks for your recognition of my work with the Airplane and Tuna.  The Hall of Fame
              did honor "Pretty As You Feel" (the famous Hot Tuna/Santana Jam) in their voter pamphlet as part of the basis to
              induct the band.  So my name is the Hall of Fame somewhere.<br />
              Joey
            </p>

            <br />

            <p className="email-message">
              <span style={boldStyle}>Date: 98-05-02 09:54:19 EDT</span><br />
              Brad Morgan<br />
              Air Personality<br />
              Hot Rockin' Country 94.5 WCEN<br />
              www.wcen.com<br />
              Joey...<br />
              ....I really admire your drumming...I'm a guitarist<br />
              however i do occansionaly bang on the skins....and really dig your jazzy<br />
              style of playing. Do you remember the story of how Marty Balin saw Skip Spence<br />
              and said "You look like a drummer"...that's what happened to me .I played drums<br />
              with my first band in high school...I really enjoyed it. Then i bought a Fender<br />
              Strat and formed my own band. Drumming taught me the imporatnce of playing in time and developing a
              sense of rythm in general.<br />
              Take Care...Joey..and ' go out there and knock em silly"<br />
              Your friend in Michigan.. Brad Morgan
            </p>

            <p className="email-response">

            </p>

            <br />

            <p className="email-message">
              <span style={boldStyle}>Date: 98-06-05 19:31:19 EDT</span><br />
              Subj: thank you<br />
              BY far the greatest drummer for JA/JS. Not only was he the most talented drummer for those bands
              but also a great song writer. He had to have frightened Paul Kantner a bit I would say. Again, thank
              you for being a part of a great music scene.<br />
              matt louck
            </p>

            <p className="email-response">

            </p>

          </div>

      }
      {
        (error) ? <p>Error loading Uncensored Page </p> : ''
      }

    </div>

  );

};



class Uncensored extends Component {

    /* Constructor */
    constructor(props) {
        // Initialize parent
        super(props);

        // set default state for component
        this.state = {
            loading: false,
            error: null
        };
        console.log('home constructor');
    }

    /* component will mount react life cycle function */
    componentWillMount(){

    }

    /* component will update react life cycle function  */
    componentWillUpdate() {

    }

    /* component did update react life cycle function  */
    componentDidMount() {

    }

    /* component render function  */
    render() {
        // load state variables
        const { loading, error } = this.state;

        return(

            <Switch>

                <Route exact path="/uncensored"
                    render={() => (
                        <Main loading={loading} error={error} />
                    )}
                />

            </Switch>

        );

    }

};

const UncensoredCntr = connect(mapStateToProps, mapDispatchToProps)(Uncensored);

export default UncensoredCntr;
