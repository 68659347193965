/* index.js - redux root reducer file */

/* Import Libraries */
import { combineReducers } from 'redux';

/* Import reducers */


/* Combine Reducers */
const rootReducer = () => {
    return combineReducers({

    });
}


export default rootReducer;
