/* page_template.js - a react page template ui component */

/* Import Libraries */
import { Component } from 'react';


/* Import Stylesheets */
import '../../stylesheets/ui/pageTemplate.css';

/* Import Components */
import Header from './header';
import Footer from './footer';

/*const mapStateToProps = state => {
  return { router: state.router };
};*/

class PageTemplate extends Component {

    render() {

        return(

            <React.Fragment>
                <Header />
                <div className="main_content">
                    {this.props.children}
                </div>
                <Footer />
            </React.Fragment>

        );
    }
}

//const Page_template = connect(mapStateToProps, null, null, { pure: false })(PageTemplate);

export default PageTemplate;
