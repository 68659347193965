/* Songs.js - a react songs component */

/* Import Libraries */
import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Switch, NavLink } from 'react-router-dom';


/* Import Components */
import NavMenu from '../menus/NavMenu';


/* Import Redux Actions */


/* Import Stylesheets */
import '../../stylesheets/components/pages/songs.css';


/* Import images */


/* Redux functions */
const mapStateToProps = state => {
    return {

    };
};

const mapDispatchToProps = dispatch => {
    return {

    };
};

/* Helper Functions */


/* Inline components */

const Main = ({ loading, error }) => {

  const boldStyle = {
    'font-weight': 'bold'
  };

  const fontSize = {
    'font-size': '12pt'
  };

  const biggerFontSize = {
    'font-size': '16pt'
  };

  const biggerFontSize2 = {
    'font-size': '20pt'
  };

  const centerBoldStyle = {
    'font-weight': 'bold',
    'text-align': 'center'
  };

  return(
    <div className="songs">

      <NavMenu />

      {
        (loading) ?
          <span>Loading Songs Page</span> :

          <div className="content">

            <br />

            <h1 style={centerBoldStyle}>
              JOEY COVINGTON<br />
              SONGS, AWARDS & COMMENTS
            </h1>

            <p style={biggerFontSize}>
              In no particular order
            </p>

            <br />

            <p>
              <span style={boldStyle}>"PRETTY AS YOU FEEL"</span>  (Covington/Cassady/Kaukonen)<br /><br />
              aka the Santana Collaboration featured on Jefferson Airplane Bark, 2400 Fulton Street,
              Jefferson Airplane Loves You, various compilations and bootlegs<br /><br />
              <span style={boldStyle}>Jefferson Airplane had 4 hits and this was one of them</span><br /><br />
              AKA the Santana Collaboration, and was featured in the Rock N' Roll Hall of Fame's Voter Pamphlet
              as part of the basis for inducting the band. <span style={boldStyle}>CONGRATULATIONS PRETTY AS YOU FEEL !</span><br /><br />
              This song was my ticket to meet Elvis "The King" Presley. Harry Jenkins, VP of RCA lived
              up to his promise that if I wrote a hit he'd send me to meet "The King".<br /><br />
              Also recorded by San Fransisco Allstars, Rainbow Allstars, and LAuren<br /><br />
              theme song of the Tom Snyder Show in the 1970's<br /><br />
              T.V. - Santa Barbara episode, China Beach Episode, and numerous documentaries
            </p>

            <br />

            <p>
              <span style={boldStyle}>"THUNK"</span> (Covington)<br /><br />
              only Jefferson Airplane Song given a good review by the New York Times<br /><br />
              became a college anthem<br /><br />
              released on Jefferson  Airplane Bark
            </p>

            <br />

            <p>
              <span style={boldStyle}>"JANITOR DRIVES A CADILLAC"</span> (Covington)<br /><br />
              PaPa John Creach's one and only Top 40 Hit<br /><br />
              Papa John's infamous duet with Grace Slick
            </p>

            <br />

            <p>
              <span style={boldStyle}>"MAU MAU AMERIKON"</span> (Kantner, Slick, Covington)<br /><br/>
              a collaboration with Paul and Grace, featured on Jefferson Starship Blows Against The Empire<br /><br/>
              "Paul was like a magician when creating this album"
            </p>

            <br />

            <h3>Songs Featured on Fat Fandango</h3>

            <p>
              <span style={boldStyle}>"MAMA NEPTUNE"</span> (Covington)
            </p>

            <br />

            <p>
              <span style={boldStyle}>"MISS UNAVERSE"</span> (Covington)
            </p>

            <br />

            <p>
              <span style={boldStyle}>"HIDEOUT IS A CROOKS BEST FRIEND"</span> (Covington)
            </p>

            <br />

            <p>
              <span style={boldStyle}>"YOUR HEART IS MY HEART"</span> (Covington)<br /><br />
              This song was released as a single in 1973
            </p>

            <br />

            <p>
              <span style={boldStyle}>"VAPOR LADY"</span> (Covington)
            </p>

            <br />

            <p>
              <span style={boldStyle}>"COUNTRY GIRL"</span> (Covington)
            </p>

            <br />

            <p>
              <span style={boldStyle}>"ZEPHYREENA"</span>  (Covington)
            </p>

            <br />

            <p>
              <span style={boldStyle}>"MOONBEAM"</span>  (Covington)<br /><br />
              "I got thrown out of the Hyatt House (the Riot House), on Sunset Blvd while recording the above,
              so this album had to be recorded up in San Francisco"<br /><br />
              This album was released to worldwide critical acclaim and still can be heard on radio<br /><br />
              Fat Fandango has been re-released to all online platforms in 2019
            </p>

            <br />

            <p>
              <span style={boldStyle}>"WITH YOUR LOVE"</span>  (Balin, Covington, Smith)<br /><br />
              release on Jefferson Starship Spitfire<br /><br />
              sung by Marty Balin<br /><br />
              A BMI 2 Millionair Award winning song - over 2 million radio plays<br /><br />
              can be heard in television and film
            </p>

            <br />

            <p>
              <span style={boldStyle}>"WHATEVER THE OLD MAN DOES (Is Always Right!)"</span> (Covington)<br /><br />
              Hot Tuna live shows<br /><br />
              part of the Hot Tuna Jamaica sessions<br /><br />
              a tape traded and bootleg staple<br /><br />
              recorded by LAuren
            </p>

            <br />

            <p>
              <span style={boldStyle}>"TONIGHT"</span> (Covington)<br /><br />
              sung Live by Marty Balin with Jefferson Starship
            </p>

            <br />

            <p>
              <span style={boldStyle}>"GET OFF"</span> (Covington)<br /><br />
              Hot Tuna Live
            </p>

            <br />

            <p>
              <span style={boldStyle}>"I'll Do Better Next Time"</span> (Covington)<br /><br />
              B side to Boris The Spider, my 1967 single<br /><br />
              one of the earliest songs I wrote<br /><br />
              A Kim Fowley production, Engineered by Michael Lloyd
            </p>

            <br />

            <p>
              <span style={boldStyle}>"Let's Be Friends"</span> (Covington/Schecter)<br /><br />
              recorded by Tsong, my Band with Mickey Rooney, Jr.
            </p>

            <br />

            <p>
              <span style={boldStyle}>"The Way We Were Before"</span> (Covington/Schecter)<br /><br />
              recorded by Tsong
            </p>

            <br />

            <p>
              <span style={boldStyle}>"The Bludgeon of The BlueCoat aka The MAN"</span> (Covington)<br /><br />
              Hot Tuna Live shows<br /><br />
              part of the Hot Tuna Jamaica sessions<br /><br />
              a bootleg staple<br /><br />
              I had been talking to Little Richard's mother, Mrs. Penniman, for 3 months. One day he called me,
              told me his mother liked me, so he came and did the session with the Airplane.<br /><br />
              This song has the honor of being nixed from release in 1971 and 1992 by RCA and BMG for
              political reasons. BMG mentioned something about Iced Tea and the NRA. Are they still censoring us?<br /><br />
              Inspired by the murder of Ruben Salazar and the beatings of Cesar Chavez.<br /><br />
              Little Richard laid down a great track!<br /><br />
              Recorded by LAuren
            </p>

            <br />

            <h3>Songs featured on LAuren’s CD Hideout Is A Crook’s Best Friend  (2006)</h3>

            <p>
              <span style={boldStyle}>“Baby I’m Amazed”</span> (Covington/Taines)
            </p>

            <br />

            <p>
              <span style={boldStyle}>“Visions”</span> (Covington)
            </p>

            <br />

            <p>
              <span style={boldStyle}>“At The Rite Time”</span> (Covington)
            </p>

            <br />

            <p>
              (Includes new versions)<br /><br />
              <span style={boldStyle}>“Pretty As You Feel”</span> (Covington/Cassady/Kaukonen)
            </p>

            <br />

            <p>
              <span style={boldStyle}>“Your Heart Is My Heart”</span> (Covington)
            </p>

            <br />

            <p>
              <span style={boldStyle}>“Hideout Is A Crook’s Best Friend”</span>
            </p>

            <br />

            <p>
              <span style={boldStyle}>“The Bludgeon of The BlueCoat aka The Man”</span> (Covington)<br /><br />
              The Man has finally been released!!!
            </p>

            <br />

            <p>
              <span style={boldStyle}>“Whatever A Woman Does (Is Always Right!)</span>  (Covington)
            </p>

            <br />

            <p>
              Musically Yours,<br />
              Joey Covington
            </p>

          </div>

      }
      {
        (error) ? <p>Error loading Songs Page </p> : ''
      }

    </div>

  );

};



class Songs extends Component {

    /* Constructor */
    constructor(props) {
        // Initialize parent
        super(props);

        // set default state for component
        this.state = {
            loading: false,
            error: null
        };
        console.log('home constructor');
    }

    /* component will mount react life cycle function */
    componentWillMount(){

    }

    /* component will update react life cycle function  */
    componentWillUpdate() {

    }

    /* component did update react life cycle function  */
    componentDidMount() {

    }

    /* component render function  */
    render() {
        // load state variables
        const { loading, error } = this.state;

        return(

            <Switch>

                <Route exact path="/songs"
                    render={() => (
                        <Main loading={loading} error={error} />
                    )}
                />

            </Switch>

        );

    }

};

const SongsCntr = connect(mapStateToProps, mapDispatchToProps)(Songs);

export default SongsCntr;
