/* NavMenu.js - a react details component */

/* Import Libraries */
import { Component } from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';


/* Import Components */
import HtmlImage from '../common/HtmlImage';

/* Import constants */


/* Import Stylesheets */
import '../../stylesheets/components/menus/navMenu.css';

/* Import images */
import Joey from '../../images/IMG_4453.jpg';


/* Functions */


class NavMenu extends Component {

  render() {

    return(

      <div className="nav_menu">

          <ul>

            <li>
                <NavLink to="/" exact activeClassName="current_page" >Home</NavLink>
            </li>

            <li>
                <NavLink to="/biography" exact activeClassName="current_page" >Biography</NavLink>
            </li>

            <li>
                <NavLink to="/ithunk" exact activeClassName="current_page" >I Thunk (What's Been Going On, 1997 - Ongoing)</NavLink>
            </li>

            <li>
                <NavLink to="/drummer" exact activeClassName="current_page" >So You Wanna Be A Drummer? Huh? - (My Dad)</NavLink>
            </li>

            <li>
                <NavLink to="/discography" exact activeClassName="current_page" >Discography</NavLink>
            </li>

            <li>
                <NavLink to="/songs" exact activeClassName="current_page" >Songs, Comments, Awards</NavLink>
            </li>

            <li>
                <NavLink to="/girlfriend" exact activeClassName="current_page" >What's A Drummer Without A Girlfriend?</NavLink>
            </li>

            <li>
                <NavLink to="/uncensored" exact activeClassName="current_page" >Totally Uncensored & Under The Radar Mail</NavLink>
            </li>

            <li>
                <NavLink to="/links" exact activeClassName="current_page" >Outstanding Links & Credits</NavLink>
            </li>

          </ul>

      </div>


    );

  }

}

export default NavMenu;
