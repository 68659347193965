/* Home.js - a react home component */

/* Import Libraries */
import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Switch, NavLink } from 'react-router-dom';


/* Import Components */
import NavMenu from '../menus/NavMenu';
import HtmlImage from '../common/HtmlImage';

/* Import Redux Actions */


/* Import Stylesheets */
import '../../stylesheets/components/pages/home.css';


/* Import images */
import Joey from '../../images/IMG_4453.jpg';
import FatFandangoFront from '../../images/Fat-Fandango-Front-Web.jpg';
import FatFandangoBack from '../../images/Fat-Fandango-Back-Web.jpg';
import FatFandangoInsideLeft from '../../images/Fat-Fandango-Inside-Left-Web.jpg';
import FatFandangoInsideRight from '../../images/Fat-Fandango-Inside-Right-Web.jpg';

/* Redux functions */
const mapStateToProps = state => {
    return {

    };
};

const mapDispatchToProps = dispatch => {
    return {

    };
};

/* Helper Functions */


/* Inline components */

const Main = ({ loading, error }) => {

  const overLineStyle = {
    'padding-top': '15px',
    'border-top': '3px solid #0000F1'
  };

  const boldStyle = {
    'font-weight': 'bold'
  };

  const centerBoldStyle = {
    'font-size': '18pt',
    'font-weight': 'bold'
  };

  const biggerFontStyle = {
    'font-size': '15pt',
    'font-weight': 'bold'
  };

  const biggerFontStyle2 = {
    'font-size': '20pt',
    'font-weight': 'bold'
  };

  return(
    <div className="home">

      {
        (loading) ?
          <span>Loading Home Page</span> :

          <div className="content">

            <p style={boldStyle}>"When all is said and done, it will be my body of work that lives on" <br /> ( Joey Covington )</p>

            <p>
              <HtmlImage id={"joey_picture"}
                         src={Joey}
                         alt={""}
                         style={{width: '400px'}} /><br />
                June 27th 1945 - June 4th 2013
            </p>

            <br />

            <p>
              Though best known for his work with Jefferson Airplane, Electric Hot Tuna, and Jefferson Starship,
              Joey Covington (born Joseph Michno) had a long career starting at age 10 as a self taught drummer/percussionist.
              Joey recorded on over 22 albums, of which 16 went gold and platinum. Along his musical journey he became a record producer
              and an award winning songwriter.
            </p>

            <br />

            <NavMenu />

            <br/>

            <p style={{...biggerFontStyle2, ...overLineStyle}}>December, 2019</p>

            <p>
              To end all confusion about Joey's pre-Airplane 1967 single, Boris The Spider, here is a link to the only "Legal",
              from the original Masters online posting. If what you've been listening to doesn't say Bicycle Music - Concord, it's
              not from the masters and it's not legal. For now, it is only available “legally” on You Tube.
            </p>

            <p>
              <iframe width="560" height="315" src="https://www.youtube.com/embed/GtUsGo6ivyA"
                      frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen></iframe>
            </p>

            <p style={{...biggerFontStyle2, ...overLineStyle}}>October, 2019</p>

            <p>
              <span style={boldStyle}>Purple Pyramid Records</span> has re-released Joe E. Covington’s Fat Fandango
              to all online music platforms!! I.E. Apple Music, Spotify,
              Amazon Music, Google Play, Etc. CD's coming in 2020!. This is Joey’s 1973 solo album,
              originally recorded for Jefferson Airplane's Grunt Records. Joey's musical legacy is now
              with the Record Label that has the solo work of - Jimmy Page, Rick Wakeman, Santana,
              Chris Squire...
            </p>

            <p>
              <span style={boldStyle}>A big Thank You</span> to the 2019 production team!<br />
              Producer - Corey E. Whitehead, D.M.A.<br />
              Executive Producer & A&R Coordinator - Lauren Taines <br />
              Digital Remastering  - Nathaniel Dahman, Dahman Music Productions <br />
              Special Thanks to David Kershenbaum & Peter Chalcraft <br />
            </p>

            <p>
              <HtmlImage id={"fat-fandango-front"}
                         src={FatFandangoFront}
                         alt={""}
                         style={{width: '400px', padding: '2px 5px'}} />
              <HtmlImage id={"fat-fandango-back"}
                        src={FatFandangoBack}
                        alt={""}
                        style={{width: '400px', padding: '2px 5px'}} />
              <HtmlImage id={"fat-fandango-inside-left"}
                       src={FatFandangoInsideLeft}
                       alt={""}
                       style={{width: '400px', padding: '2px 5px'}} />
              <HtmlImage id={"fat-fandango-inside-right"}
                      src={FatFandangoInsideRight}
                      alt={""}
                      style={{width: '400px', padding: '2px 5px'}} />
            </p>

            <p>
              Check it out! - YouTube link on <NavLink to="/ithunk">I Thunk</NavLink>
            </p>

            <p>
              <span style={biggerFontStyle}>“Of all the Airplane-related solo recordings, drummer-singer Joey Covington’s Fat Fandango is one of the quirkiest.
              This album is truly one of the most underrated of the era, decades ahead of it’s time.”</span>
              Jeff Tamarkin (Author of Got a Revolution!  The Turbulent Flight of Jefferson Airplane)
            </p>

            <br />

          </div>

      }
      {
        (error) ? <p>Error loading Home Page </p> : ''
      }

    </div>

  );

};



class Home extends Component {

    /* Constructor */
    constructor(props) {
        // Initialize parent
        super(props);

        // set default state for component
        this.state = {
            loading: false,
            error: null
        };
        console.log('home constructor');
    }

    /* component will mount react life cycle function */
    componentWillMount(){

    }

    /* component will update react life cycle function  */
    componentWillUpdate() {

    }

    /* component did update react life cycle function  */
    componentDidMount() {

    }

    /* component render function  */
    render() {
        // load state variables
        const { loading, error } = this.state;

        return(

            <Switch>

                <Route exact path="/"
                    render={() => (
                        <Main loading={loading} error={error} />
                    )}
                />

            </Switch>

        );

    }

};

const HomeCntr = connect(mapStateToProps, mapDispatchToProps)(Home);

export default HomeCntr;
