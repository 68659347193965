/* index.js - entry point file */

/* Import libraries */
import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { HashRouter} from 'react-router-dom';

/* Import redux */
import storeFactory from './store/index';



/* Import Components */
import App from './components/App';

/* Variables */
const store = storeFactory();

window.React = React;

/* render


*/
render(
    <Provider store={store}>
        <HashRouter>
            <App />
        </HashRouter>
    </Provider>,
    document.querySelector('#react-container')
);
