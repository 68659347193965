/* Discography.js - a react discography component */

/* Import Libraries */
import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Switch, NavLink } from 'react-router-dom';


/* Import Components */
import NavMenu from '../menus/NavMenu';
import HtmlImage from '../common/HtmlImage';


/* Import Redux Actions */


/* Import Stylesheets */
import '../../stylesheets/components/pages/discography.css';


/* Import images */
import Vibrasonics from '../../images/vibrasonics-drag-race-ideal-pennsylvania.jpg';
import Fenways from '../../images/fenwaysimamover.jpg';

/* Redux functions */
const mapStateToProps = state => {
    return {

    };
};

const mapDispatchToProps = dispatch => {
    return {

    };
};

/* Helper Functions */


/* Inline components */

const Main = ({ loading, error }) => {

  const boldStyle = {
    'font-weight': 'bold'
  };

  const fontSize = {
    'font-size': '12pt'
  };

  const biggerFontSize = {
    'font-size': '16pt'
  };

  const biggerFontSize2 = {
    'font-size': '20pt'
  };

  const centerBoldStyle = {
    'font-weight': 'bold',
    'text-align': 'center'
  };

  const morePadding = {
    'padding-bottom': '15px'
  };

  return(
    <div className="discography">

      <NavMenu />

      {
        (loading) ?
          <span>Loading Discography Page</span> :

          <div className="content">

            <br />

            <h1 style={centerBoldStyle}>
              The Discography of Joey Covington
            </h1>

            <br />

            <h2 style={{...biggerFontSize2, ...centerBoldStyle}}>Singles</h2>

            <div className="media">

              <p className="title">
                <span style={{...biggerFontSize, ...boldStyle}}>Artist</span><br />
                THE VIBRASONICS
              </p>

              <p className="artwork">
                <HtmlImage id={"vibrasonics"}
                           src={Vibrasonics}
                           alt={""}
                           style={{width: '200px'}} />
              </p>

              <p className="song_titles">
                "Thunderstorm"<br />
                "Drag Race"
              </p>

              <br className="clear" />

              <p className="note">

              </p>

            </div>

            <div className="media">

              <p className="title">
                <span style={{...biggerFontSize, ...boldStyle}}>Artist</span><br />
                THE FENWAYS
              </p>

              <p className="artwork">
                <HtmlImage id={"fenways"}
                           src={Fenways}
                           alt={""}
                           style={{width: '200px'}} />
              </p>

              <p className="song_titles">
                "I'm A Mover"<br />
                “Satisfied”<br />
                “A Go Go”<br />
                “C”
              </p>

              <br className="clear" />

              <p className="note">
                (Note**bandleader turned down song Hanky Panky - there's a few others I did with them & we're working
                on tracking them down)
              </p>

          </div>

          <div className="media">

            <p className="title">
              <span style={{...biggerFontSize, ...boldStyle}}>Artist</span><br />
              JEFFERSON AIRPLANE
            </p>

            <p className="artwork">

            </p>

            <p className="song_titles">
              "Mexico"<br />
              "Have You Seen The Saucers"<br />
              "Wild Turkey"<br />
              "Pretty As You Feel"<br />
              with guests<br />
              Carlos Santana - guitar,  Michael Shrieve - Drums
            </p>

            <br className="clear" />

            <p className="note">

            </p>

          </div>

          <div className="media">

            <p className="title">
              <span style={{...biggerFontSize, ...boldStyle}}>Artist</span><br />
              JOEY COVINGTON
            </p>

            <p className="artwork">

            </p>

            <p className="song_titles">
              "Boris The Spider"<br />
              "I'll Do Better Next Time"<br />
              a Kim Fowley Production
            </p>

            <br className="clear" />

            <p className="note">

            </p>

          </div>

          <div className="media">

            <p className="title">
              <span style={{...biggerFontSize, ...boldStyle}}>Artist</span><br />
              TSONG
            </p>

            <p className="artwork">

            </p>

            <p className="song_titles">
              "Let's Be Friends"<br />
              "Like We Were Before"
            </p>

            <br className="clear" />

          <p className="note">

          </p>

        </div>


        <h2 style={{...biggerFontSize2, ...centerBoldStyle}}>Albums</h2>

            <p>
              <span style={{...biggerFontSize, ...boldStyle}}>Artist</span><br />
              JEFFERSON AIRPLANE<br />
              Volunteers<br />
              Early Flight<br />
              Bark<br />
              Long John Silver<br />
              Flight Log<br />
              2400 Fulton Street<br />
              Jefferson Airplane Loves You<br />
              The Best of Jefferson Airplane<br />
              Essential Jefferson Airplane<br />
              Journey - The Best Of Jefferson Airplane<br />
              Jefferson Airplane - White Rabbit - The Ultimate Collection<br />
              The Very Best of Jefferson Airplane<br />
              Various Jefferson Airplane Foreign releases<br />
              Various Reissues
            </p>

            <p>
              <span style={{...biggerFontSize, ...boldStyle}}>Artist</span><br />
              PAUL KANTNER - JEFFERSON STARSHIP<br />
              Blows Against The Empire
            </p>

            <p>
              <span style={{...biggerFontSize, ...boldStyle}}>Artist</span><br />
              GRACE SLICK<br />
              Sunfighter
            </p>

            <p>
              <span style={{...biggerFontSize, ...boldStyle}}>Artist</span><br />
              PAPA JOHN CREACH<br />
              PaPa John Creach
            </p>

            <p>
              <span style={{...biggerFontSize, ...boldStyle}}>Artist</span><br />
              PETER KAUKONEN<br />
              Black Kangaroo
            </p>

            <p>
              <span style={{...biggerFontSize, ...boldStyle}}>Artist</span><br />
              JOEY COVINGTON<br />
              Fat Fandango
            </p>

            <p>
              <span style={{...biggerFontSize, ...boldStyle}}>Artist</span><br />
              NICK GRAVENITES<br />
              BLUESTAR
            </p>

            <p>
              <span style={{...biggerFontSize, ...boldStyle}}>Artist</span><br />
              <span style={biggerFontSize}>LAuren</span><br />
              HIDEOUT IS A CROOK’S BEST FRIEND
            </p>

            <p>
              <span style={{...biggerFontSize, ...boldStyle}}>Artist</span><br />
              JOEY COVINGTON, JORMA KAUKONEN, JACK CASADY<br />
              Before We Were Them
            </p>

            <br />

            <h2  style={{...biggerFontSize2, ...centerBoldStyle}}>Albums as Producer</h2>

            <p>
              Joe E. Covington’s Fat Fandango  1973, 2003<br />
              LAuren - Hideout Is A Crook’s Best Friend 2006
            </p>

            <p>
              Joey Covington's Songs From Then And Now (unreleased)
            </p>

            <br />

            <p>
              <span style={{...biggerFontSize, ...boldStyle}}>ANSWERS TO QUESTIONS I'M ALWAYS ASKED:</span>
            </p>
            <p>
              <span style={biggerFontSize}>My Personal Favorite Drum Tracks are;</span><br/>
              Lawman & Wild Turkey on Bark Album<br />
              Have You Seen The Saucers & Mexico on Early Flight<br />
              Janitor Drives A Cadillac  on PaPa John Creach<br />
              Holding Together  & Sunfighter on Sunfighter<br />
              Hideout is A Crooks Best Friend on Fat Fandango<br />
            </p>

          </div>

      }
      {
        (error) ? <p>Error loading Discography Page </p> : ''
      }

    </div>

  );

};



class Discography extends Component {

    /* Constructor */
    constructor(props) {
        // Initialize parent
        super(props);

        // set default state for component
        this.state = {
            loading: false,
            error: null
        };
        console.log('home constructor');
    }

    /* component will mount react life cycle function */
    componentWillMount(){

    }

    /* component will update react life cycle function  */
    componentWillUpdate() {

    }

    /* component did update react life cycle function  */
    componentDidMount() {

    }

    /* component render function  */
    render() {
        // load state variables
        const { loading, error } = this.state;

        return(

            <Switch>

                <Route exact path="/discography"
                    render={() => (
                        <Main loading={loading} error={error} />
                    )}
                />

            </Switch>

        );

    }

};

const DiscographyCntr = connect(mapStateToProps, mapDispatchToProps)(Discography);

export default DiscographyCntr;
