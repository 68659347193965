/* HtmlImage.js - a react html image component */

/* Import Libraries */
import PropTypes from 'prop-types';


const HtmlImage = ({ id, src, alt, style }) => {

    return(
        <img className="html-image"
             id={id}
             src={src}
             alt={alt}
             style={style}/>
    );

};

HtmlImage.propTypes = {
    id: PropTypes.string.isRequired,
    src: PropTypes.string.isRequired,
    alt: PropTypes.string,
    style: PropTypes.string
};


export default HtmlImage;
