/* Links.js - a react links component */

/* Import Libraries */
import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Switch, NavLink } from 'react-router-dom';


/* Import Components */
import NavMenu from '../menus/NavMenu';


/* Import Redux Actions */


/* Import Stylesheets */
import '../../stylesheets/components/pages/links.css';


/* Import images */


/* Redux functions */
const mapStateToProps = state => {
    return {

    };
};

const mapDispatchToProps = dispatch => {
    return {

    };
};

/* Helper Functions */


/* Inline components */

const Main = ({ loading, error }) => {

  return(
    <div className="links">

      <NavMenu />

      {
        (loading) ?
          <span>Loading Links Page</span> :

          <div className="content">

            <h1>Links</h1>

            <ul>

              <li>
                <a href="https://www.youtube.com/playlist?list=PLUEA5tQFIucsQW3Fy4G6n7-dqFOP22YuW&">Fat Fandango You Tube Playlist</a>
              </li>

              <li>
                <a href="http://www.lalauren.com/">LAuren</a>
              </li>

              <li>
                <a href="http://www.jeffersonairplane.com/">Jefferson Airplane Official Site</a>
              </li>

              <li>
                <a href="http://www.floydredcrowwesterman.com/">Floyd Red Crow Westerman</a>
              </li>

              <li>
                <a href="http://www.dahmanmusic.com/">DAHMAN Music</a>
              </li>

              <li>
                <a href="http://www.fallofsilence.com/">Fall of Silence - hot new band!</a>
              </li>

            </ul>

            <h1>FAT FANDANGO REVIEW</h1>

            <ul>

              <li>
                <a href="http://www.allmusic.com/album/fat-fandango-mw0000838465">Fat Fandango Review (best read on Joey’s vision for this record)</a>
              </li>

            </ul>



            <h1>OUTSTANDING SPORTS SITES</h1>

            <ul>

              <li>
                <a href="http://www.albaco.com/">Albaco Collectables for Enthusiasts of Ferrari (& other Italian automobiles)</a>
              </li>

              <li>
                <a href="http://www.albaco.net/tornado/index.html">Toronado Racing Team  “the golden years of Off Shore Power Boat Racing”</a>
              </li>

            </ul>

          </div>

      }
      {
        (error) ? <p>Error loading Links Page </p> : ''
      }

    </div>

  );

};



class Links extends Component {

    /* Constructor */
    constructor(props) {
        // Initialize parent
        super(props);

        // set default state for component
        this.state = {
            loading: false,
            error: null
        };
        console.log('home constructor');
    }

    /* component will mount react life cycle function */
    componentWillMount(){

    }

    /* component will update react life cycle function  */
    componentWillUpdate() {

    }

    /* component did update react life cycle function  */
    componentDidMount() {

    }

    /* component render function  */
    render() {
        // load state variables
        const { loading, error } = this.state;

        return(

            <Switch>

                <Route exact path="/links"
                    render={() => (
                        <Main loading={loading} error={error} />
                    )}
                />

            </Switch>

        );

    }

};

const LinksCntr = connect(mapStateToProps, mapDispatchToProps)(Links);

export default LinksCntr;
