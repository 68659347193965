/* footer.js - a react footer ui component */

/* Import Libraries */
import { Component } from 'react';

/* Import stylesheets */
import '../../stylesheets/ui/footer.css';

class Footer extends Component {

  render() {
    return(
      <footer className="main_footer">

        <p>
          Your comments are always welcome. Mail us at BANDINUSA@AOL.COM
        </p>

        <p>
          This page is maintained by Jehrico Rose, Inc.  on behalf of the Estate of Joey Covington aka Joseph Edward Covington.
        </p>

        <p className="footer_copy" dangerouslySetInnerHTML={{ '__html': 'Copyright &copy;  2019 - All Rights Reserved' }} />

        <p>
          This site was last updated October, 2019
        </p>

      </footer>
    );
  }

}

export default Footer;
